import {
  FooterProvider1,
  FooterProvider2,
  FooterProvider3,
  FooterProvider4,
  FooterProvider5,
  FooterProvider6,
  FooterProvider7,
  FooterProvider8,
  FooterProvider9,
  FooterProvider10,
} from '../../images';

export const footerProviders = [
  {
    icon: FooterProvider1,
  },
  {
    icon: FooterProvider2,
  },
  {
    icon: FooterProvider3,
  },
  {
    icon: FooterProvider4,
  },
  {
    icon: FooterProvider5,
  },
  {
    icon: FooterProvider6,
  },
  {
    icon: FooterProvider7,
  },
  {
    icon: FooterProvider8,
  },
  {
    icon: FooterProvider9,
  },
  {
    icon: FooterProvider10,
  },
];
