const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const getBonus = (data) => axios
  .request({
    method: 'GET',
    url: `${ENDPOINT}/v1/bonus`,
    params: data,
    headers: {
      Authorization: `Bearer ${
        Cookies.get('accessToken') || data?.accessToken
      }`,
    },
  })
  .then((response) => response)
  .catch((error) => error);

const getBonusOne = (data) => axios
  .request({
    method: 'GET',
    url: `${ENDPOINT}/bonus`,
    params: data,
    headers: {
      Authorization: `Bearer ${
        Cookies.get('accessToken') || data?.accessToken
      }`,
    },
  })
  .then((response) => response)
  .catch((error) => error);

module.exports = {
  getBonus,
  getBonusOne
};
