import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import FreeCreditComponents from '../DailyFreeCredit/FreeCreditComponents';

export default function PopupGamiModal({ show, toggleShow }) {
  const isLanguage = useSelector((state) => state.setting.locale);
  const isMobile = useSelector((state) => state.setting.isMobile);
  const { t } = useTranslation('common');

  if (show) {
    return (
      <div
        tabIndex='-1'
        className={
          show
            ? 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
            : 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary md:px-60'
        }
      >
        <div
          className='overlay-closeable'
          onClick={() => {
            toggleShow(false);
          }}
        />
        <div
          className='relative rounded-xl z-10 modal-dialog flex flex-col justify-end !pt-0 !mt-2'
          style={{
            background: 'none',
            minHeight: 600,
          }}
        >
          {/* <div className='absolute -top-6 right-0 left-0 m-auto text-center md:m-0 md:text-end md:-right-4 md:top-1'>
            <button
              className='rounded-full close-button relative z-10'
              onClick={() => {
                toggleShow(false);
              }}
            >
              <CloseCircleFilled
                fill='white'
                style={{ fontSize: '35px', color: 'white' }}
              />
            </button>
          </div> */}
          <div
            className='place-items-center grid'
          >

            <FreeCreditComponents />

            <div className='flex flex-col items-center justify-center relative z-2'>
              <label className='customer-checkout-container'>
                <p className='text-sm font-normal'>{t('deposit.neverShowAgain')}</p>
                <input type='checkbox' />
                <span className='checkmark' />
              </label>
              <button
                className='rounded-full relative z-10'
                onClick={() => {
                  toggleShow(false);
                }}
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='38' height='38' fill='currentColor' className='bi bi-x-circle' viewBox='0 0 16 16'>
                  <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16' />
                  <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708' />
                </svg>
              </button>
            </div>

          </div>
        </div>
      </div>
    );
  }
  return null;
}
