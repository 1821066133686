/* eslint-disable no-use-before-define */
import { Button } from 'antd';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBrand } from '../../../api/getBrand';
import { getGameCred } from '../../../api/getGameCred';
import {
  MenuIconfaqleft,
  MenuIconhistoryleft,
  MenuIconNews,
  MenuIconreferralleft,
  MenuIcontermleft,
  NavmenuLivecasino,
  SideMenu10,
  SideMenu11,
  SideMenu12,
  SideMenu13,
  SideMenu6,
  SideMenu9,
  SideMenuBg10,
  SideMenuBg6,
  SideMenuBg7,
  SideMenuBg8,
  SideMenuBg9,
  SideMenuSub10,
  SideMenuSub11,
  SideMenuSub12,
  SideMenuSub13,
  SideMenuSub8,
  SideMenuSub9,
} from '../../../images';
import ChangeLanguageButton from '../../changeLanguageButton';
import { SettingActions } from '../../reducers/settingReducer';
import SideMenu from './SideMenu';
import SideMenuBackground from './SideMenuBackground';
import SideMenuDropdown from './SideMenuDropdown';

function Sidebar({
  sidemenuTab,
  setSidemenuTab,
  hideSidebar,
  setHideSidebar,
  setMenuSelected,
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const isLogin = Cookies.get('accessToken');
  const isMobile = useSelector((state) => state.setting.isMobile);
  const { t, i18n } = useTranslation('common');

  const menuList2 = [
    {
      key: 0,
      label: t('common.vip'),
      type: '',
      icon: SideMenu9,
      bg: SideMenuBg6,
      textStyle: { color: '#542000', textShadow: '1px 1px #fffe84' },
      action: () => {
        if (isLogin) {
          dispatch(SettingActions.setLoading(true));
          router.push({
            pathname: '/vip',
          });
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      key: 1,
      label: t('common.luckySpin'),
      type: '',
      icon: SideMenu10,
      bg: SideMenuBg8,
      textStyle: { color: '#2c002b', textShadow: '1px 1px #f4ae8a' },
      action: () => {
        if (isLogin) {
          dispatch(SettingActions.setLoading(true));
          router.push({
            pathname: '/spinwheel',
          });
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      key: 2,
      label: t('common.dailyFreeCredit'),
      type: '',
      icon: SideMenu11,
      bg: SideMenuBg7,
      textStyle: { color: '#652600', textShadow: '1px 1px #fffe84' },
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push({
          pathname: '/daily-free-credit',
        });
      },
    },
    {
      key: 3,
      label: t('common.jackpot'),
      type: '',
      icon: SideMenu12,
      bg: SideMenuBg9,
      textStyle: { color: '#000000', textShadow: '1px 1px #e5cce1' },
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push({
          pathname: '/jackpot',
        });
      },
    },
    {
      key: 4,
      label: t('common.promotions'),
      type: '',
      icon: SideMenu13,
      bg: SideMenuBg10,
      textStyle: { color: '#000000', textShadow: '1px 1px #00c9cc' },
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push({
          pathname: '/promotions',
        });
      },
    },
  ];

  const menuList3 = [
    {
      key: 99,
      label: t('common.history'),
      type: '',
      icon: MenuIconhistoryleft,
      action: () => {
        if (isLogin) {
          dispatch(SettingActions.setLoading(true));
          router.push('/history');
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      key: 8,
      label: t('setting.referral'),
      type: '',
      icon: MenuIconreferralleft,
      action: () => {
        if (isLogin) {
          dispatch(SettingActions.setLoading(true));
          router.push({
            pathname: '/referral',
          });
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      key: 10,
      label: t('common.faq'),
      type: '',
      icon: MenuIconfaqleft,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/faq');
      },
    },
    {
      key: 12,
      label: t('common.news'),
      type: '',
      icon: MenuIconNews,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/news');
      },
    },
    {
      key: 11,
      label: t('common.termsConditions'),
      type: '',
      icon: MenuIcontermleft,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/terms-and-condition');
      },
    },
  ];

  const menuList4 = [
    {
      key: 12,
      label: t('common.games'),
      type: '',
      icon: SideMenu6,
      subItems: [
        {
          key: 0,
          label: t('common.slots'),
          icon: SideMenuSub9,
          type: '0',
          action: () => {
            dispatch(SettingActions.setLoading(true));
            dispatch(SettingActions.setSelected(0));
            router.push({
              pathname: '/slots',
            });
          },
        },
        {
          key: 1,
          label: t('common.instantGames'),
          icon: SideMenuSub8,
          type: '0',
          action: () => {
            dispatch(SettingActions.setLoading(true));
            dispatch(SettingActions.setSelected(0));
            router.push({
              pathname: '/instants',
            });
          },
        },
        {
          key: 2,
          label: t('common.liveCasino'),
          icon: SideMenuSub10,
          type: '0',
          action: () => {
            dispatch(SettingActions.setLoading(true));
            dispatch(SettingActions.setSelected(0));
            router.push({
              pathname: '/live-casino',
            });
          },
        },
        {
          key: 3,
          label: t('game.tableGames'),
          icon: SideMenuSub11,
          type: '0',
          action: () => {
            dispatch(SettingActions.setLoading(true));
            dispatch(SettingActions.setSelected(0));
            router.push({
              pathname: '/tables',
            });
          },
        },
        {
          key: 4,
          label: t('common.sports'),
          icon: SideMenuSub13,
          type: '0',
          action: () => {
            dispatch(SettingActions.setLoading(true));
            accessToBti();
          },
        },
        // {
        //   key: 0,
        //   label: t('home.recentPlayed'),
        //   icon: SideMenuSub5,
        //   type: '0',
        //   action: () => {
        //     dispatch(SettingActions.setLoading(true));
        //     dispatch(SettingActions.setSelected(0));
        //     router.push({
        //       pathname: '/recent-played',
        //       query: { type: '12', value: 1, filter: 'cricket' },
        //     });
        //   },
        // },
      ],
    },
    // {
    //   key: 99,
    //   label: t('home.sports'),
    //   type: '',
    //   icon: SideMenu7,
    //   subItems: [
    //     {
    //       key: 0,
    //       label: 'BTI',
    //       icon: SideMenuSub6,
    //       type: '0',
    //       action: () => {
    //         dispatch(SettingActions.setLoading(true));
    //         dispatch(SettingActions.setSelected(0));
    //         router.push({
    //           pathname: '/games',
    //           query: { type: '12', value: 1, filter: 'cricket' },
    //         });
    //       },
    //     },
    //   ],
    // },
    // {
    //   key: 99,
    //   label: t('common.activities'),
    //   type: '',
    //   icon: SideMenu8,
    //   subItems: [
    //     {
    //       key: 0,
    //       label: t('home.luckyCoinJackpot'),
    //       icon: SideMenuSub7,
    //       type: '0',
    //       action: () => {
    //         dispatch(SettingActions.setLoading(true));
    //         dispatch(SettingActions.setSelected(0));
    //         router.push({
    //           pathname: '/games',
    //           query: { type: '12', value: 1, filter: 'cricket' },
    //         });
    //       },
    //     },
    //     {
    //       key: 0,
    //       label: t('home.luckyRedEnvelope'),
    //       icon: SideMenuSub7,
    //       type: '0',
    //       action: () => {
    //         dispatch(SettingActions.setLoading(true));
    //         dispatch(SettingActions.setSelected(0));
    //         router.push({
    //           pathname: '/games',
    //           query: { type: '12', value: 1, filter: 'cricket' },
    //         });
    //       },
    //     },
    //     {
    //       key: 0,
    //       label: t('home.luckyRedEnvelope'),
    //       icon: SideMenuSub7,
    //       type: '0',
    //       action: () => {
    //         dispatch(SettingActions.setLoading(true));
    //         dispatch(SettingActions.setSelected(0));
    //         router.push({
    //           pathname: '/games',
    //           query: { type: '12', value: 1, filter: 'cricket' },
    //         });
    //       },
    //     },
    //     {
    //       key: 0,
    //       label: t('home.luckyCoinJackpot'),
    //       icon: SideMenuSub7,
    //       type: '0',
    //       action: () => {
    //         dispatch(SettingActions.setLoading(true));
    //         dispatch(SettingActions.setSelected(0));
    //         router.push({
    //           pathname: '/games',
    //           query: { type: '12', value: 1, filter: 'cricket' },
    //         });
    //       },
    //     },
    //   ],
    // },
  ];

  const accessToBti = async () => {
    if (!Cookies.get('accessToken')) {
      return dispatch(
        SettingActions.setAlert([
          {
            msg: 'Please login',
          },
        ]),
      );
    }

    const brandBtitId = '6656fbd28afd0a4d83d43daf';
    const brandInfo = await getBrand(brandBtitId);
    const brandId = brandInfo?.data?._id;
    const gameId = brandInfo?.data?.games?._id;
    const gameInfo = await getGameCred({ brandId: brandBtitId });
    if (gameInfo?.error) {
      dispatch(
        SettingActions.setAlert([
          {
            msg: gameInfo?.error,
          },
        ]),
      );
      dispatch(SettingActions.setLoading(false));
    } else if (gameInfo) {
      const gameProfileId = gameInfo?.data?.data?.[0]._id;
      router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}`);
    }
  };

  return (
    <>
      {
        (isMobile && !hideSidebar)
    && (
      <div
        className='overlay-closeable z-[20]'
        onClick={() => { setHideSidebar(!hideSidebar); }}
      />
    )
      }
      <div
        className={`h-screen pb-20 transition-all duration-300 ease-linear bg-dark z-40 ${isMobile && 'absolute top-0 left-0'}`}
        style={{
          maxWidth: isMobile ? (hideSidebar ? 0 : 230) : (hideSidebar ? 76 : 260),
          width: '100%',
        }}
      >
        <div>
          <div
            className='relative bg-dark-100 h-[4rem] flex items-center justify-between transition-all mx-4 gap-2'
          >
            <Button
              className={`button-tab ${sidemenuTab === 0 && 'button-tab-active'}  py-2 lg:py-2.5 w-full rounded-full`}
              onClick={() => setSidemenuTab(0)}
            >
              <div className='flex items-center justify-center'>
                <div style={{ filter: 'invert(99%) sepia(4%) saturate(195%) hue-rotate(133deg) brightness(215%) contrast(100%)', width: 16 }} className={`${hideSidebar ? 'm-auto' : 'mr-1'} leading-none`}>
                  <Image src={NavmenuLivecasino} alt='live casino button' />
                </div>
                {
                  !hideSidebar && (
                    <span className='font-bold text-2xs lg:text-xs uppercase'>
                      {t('home.casino')}
                    </span>
                  )
                }
              </div>
            </Button>
            {
              !hideSidebar && (
                // <Button
                //   className={`button-tab ${sidemenuTab === 1 && 'button-tab-active'} py-2 lg:py-2.5 w-full rounded-full`}
                //   disabled
                //   onClick={() => {
                //     if (isLogin) {
                //       setSidemenuTab(1);
                //     } else {
                //       dispatch(SettingActions.setLoginShow(true));
                //     }
                //   }}
                // >
                //   <Tooltip title='Coming Soon' className='cursor-pointer' color='#222E39'>
                //     <div className='flex items-center justify-center'>
                //       <div style={{ width: 16 }} className='mr-1 leading-none'>
                //         <Image src={NavbarSports} alt='' />
                //       </div>
                //       <span className='font-bold text-2xs lg:text-xs uppercase'>
                //         {t('home.esports')}
                //       </span>
                //     </div>
                //   </Tooltip>
                // </Button>
                <div>
                  <ChangeLanguageButton />
                </div>
              )
            }
          </div>

          <div
          // id='left-sider'
            className='h-screen overflow-y-auto hidden-scrollbar pb-20 bg-dark'
          >
            <div className='overflow-y-auto'>
              <div>
                <SideMenuBackground
                  menuList={menuList2}
                  setMenuSelected={setMenuSelected}
                  hideSidebar={hideSidebar}
                />
              </div>

              <div>
                <SideMenuDropdown
                  menuList={menuList4}
                  setMenuSelected={setMenuSelected}
                  hideSidebar={hideSidebar}
                />
              </div>

              <div>
                <SideMenu
                  menuList={menuList3}
                  setMenuSelected={setMenuSelected}
                  hideSidebar={hideSidebar}
                />
              </div>

              {/* <a target='_blank' href='https://betleon.s3.ap-southeast-1.amazonaws.com/apk/BETLEON77.apk' rel='noopener noreferrer'> */}
              {/* <div className='rounded-xl overflow-hidden mt-6'>
                <Image src={DownloadApk} alt='APP QR Code' />
              </div> */}
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
