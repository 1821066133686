import {
  Button, Form, Input, message,
} from 'antd';
import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
// import ReactCaptcha from 'modern-react-captcha';
import { SettingActions } from '../reducers/settingReducer';
import { sendEmail } from '../../api/sendEmail';

function ContactUsModal({ show, toggleModal }) {
  const branchId = process.env.BRANCH_ID;
  const [form] = Form.useForm();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [captchaPassed, setCaptchaPassed] = useState(false);

  const contactUsEnquiryReasonList = [
    {
      text: t('deposit.depositWithdrawal'),
    },
    {
      text: t('deposit.bonusIssue'),
    },
    {
      text: t('deposit.accountIssue'),
    },
    {
      text: t('deposit.productsIssue'),
    },
    {
      text: t('deposit.others'),
    },
  ];

  useEffect(() => {
    dispatch(SettingActions.setLoading(false));
  }, []);

  const onFinish = async (values) => {
    if (captchaPassed) {
      dispatch(SettingActions.setLoading(true));
      const result = await sendEmail({ ...values, branchId });

      if (result?.data?.status) {
        toggleModal();
        form.resetFields();
        dispatch(SettingActions.setLoading(false));
        dispatch(
          SettingActions.setAlert([
            {
              msg: t('common.mailSentSuccesfully'),
            },
          ]),
        );
      } else {
        toggleModal();
        dispatch(SettingActions.setLoading(false));
        dispatch(
          SettingActions.setAlert([
            {
              msg: t('common.somethingWentWrong'),
            },
          ]),
        );
      }
    } else {
      message.error(t('common.invalidCaptcha'));
    }
  };

  const handleSuccess = () => setCaptchaPassed(true);
  const handleFailure = () => setCaptchaPassed(false);

  return (
    <div
      tabIndex='-1'
      className={
        show
          ? 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
          : 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary md:px-60'
      }
      role='dialog'
    >
      <div className='overlay-closeable' onClick={() => toggleModal()} />
      <div className='relative py-4 md:pt-5 pb-5 rounded-xl z-10 modal-dialog'>
        <div className='mx-5'>
          <div className='absolute top-1 right-2 m-auto text-center'>
            <Button
              onClick={() => toggleModal()}
              className='close-button relative z-10'
            >
              <CloseOutlined
                fill='white'
                style={{ fontSize: '20px', color: 'white' }}
              />
            </Button>
          </div>
          <div className='mb-5 text-lg font-medium flex justify-center items-center font-bold'>
            {t('common.contactUs')}
          </div>
          <Form id='ContactUsModal' name='form' form={form} onFinish={onFinish}>
            <div>
              <div>
                <Form.Item
                  name='enquiryReason'
                  className='my-2'
                  label={t('help.whatCanWeHelp')}
                  rules={[
                    {
                      required: true,
                      message: t('help.inquiryReason'),
                    },
                  ]}
                >
                  <select className='flex justify-between w-full rounded-lg h-10 mt-2 px-2 input-primary'>
                    <option>{t('wallet.pleaseSelect')}</option>
                    {contactUsEnquiryReasonList.map((i, index) => (
                      <option value={i.text} key={`reason-${index}`}>
                        {`${i.text}`}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </div>

              <div className='my-3'>
                <Form.Item
                  label={t('help.yourName')}
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: t('help.inputYourName'),
                    },
                  ]}
                >
                  <Input
                    type='text'
                    className='w-full rounded-lg h-11 mt-1 px-4'
                    placeholder={t('deposit.pleaseEnterYourName')}
                  />
                </Form.Item>
              </div>

              <div className='my-3'>
                <Form.Item
                  label={t('register.email')}
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: t('help.inputEmailAdress'),
                    },
                  ]}
                >
                  <Input
                    type='email'
                    className='w-full rounded-lg h-11 mt-1 px-4'
                    placeholder={t('register.pleaseInputEmail')}
                  />
                </Form.Item>
              </div>

              <div className='my-3'>
                <Form.Item
                  name='captcha'
                  label={t('deposit.captcha')}
                  className='mb-3 '
                >
                  {/* <ReactCaptcha
                    charset='ulns'
                    length={7}
                    reload
                    bgColor='#232C35'
                    reloadText=''
                    reloadIcon='https://png.pngtree.com/png-vector/20190223/ourmid/pngtree-reload-vector-icon-png-image_696434.jpg'
                    handleSuccess={handleSuccess}
                    handleFailure={handleFailure}
                  /> */}
                </Form.Item>
              </div>

              <div className='my-3'>
                <Form.Item
                  label={t('help.subject')}
                  name='subject'
                  rules={[
                    {
                      required: true,
                      message: t('help.inputSubject'),
                    },
                  ]}
                >
                  <Input
                    type='text'
                    className='w-full rounded-lg h-11 mt-1 px-4'
                    placeholder={t('help.inputSubject')}
                  />
                </Form.Item>
              </div>

              <div className='my-3'>
                <Form.Item
                  label={t('help.details')}
                  name='details'
                  rules={[
                    {
                      required: true,
                      message: t('help.inputDetails'),
                    },
                  ]}
                >
                  <TextArea
                    className='w-full rounded-lg mt-1 p-4 bg-gray-600'
                    autoSize={{ minRows: 5, maxRows: 10 }}
                    placeholder={t('help.inputDetails')}
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item className='text-center'>
              <Button
                htmlType='submit'
                className='rounded-lg w-full my-5 button-primary py-2.5'
              >
                <span className='text-base font-normal leading-none'>{t('common.submit')}</span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ContactUsModal;
