import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

function StrengthMeter(props) {
  const { t } = useTranslation('common');
  const pwdValidate = props.password;
  const initPwdChecker = () => {
    let pwdCheck = 0;
    const validateRegex = ['[A-Z]', '[a-z]', '[0-9]', '\\W'];
    validateRegex.forEach((regex, i) => {
      if (new RegExp(regex).test(pwdValidate)) {
        pwdCheck += 1;
      }
    });
    switch (pwdCheck) {
    case 0:
      return {
        strength: 0,
        val: '',
      };
    case 1:
      return {
        strength: 1,
        val: t('register.weak'),
      };
    case 2:
      return {
        strength: 2,
        val: t('register.fair'),
      };
    case 3:
      return {
        strength: 3,
        val: t('register.good'),
      };
    case 4:
      return {
        strength: 4,
        val: t('register.strong'),
      };
    default:
      return null;
    }
  };
  useEffect(() => {
    props.actions(initPwdChecker().val);
  }, [props.actions, props.password]);

  return (
    <div className='password-wrapper'>
      <progress
        className={`pwd-checker-bar strength-${initPwdChecker().val}`}
        value={initPwdChecker().strength}
        max='4'
      />
      <br />
      <p className='pwd-label'>
        {props.password && (
          <div>
            <p className={`label strength-${initPwdChecker().val}`}>
              {t('register.passwordStrength')}
              <strong>
                {initPwdChecker().val}
                {' '}
              </strong>
            </p>
          </div>
        )}
      </p>
    </div>
  );
}

export default StrengthMeter;
