import { i18n, useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import {
  logoIcon,
} from '../../images';
import { footerProviders } from '../constant/footerContent';
import { socialMediaList } from '../constant/socialList';

function Footer({
  setContactShow,
}) {
  const router = useRouter();
  const { t } = useTranslation('common');
  const { language } = i18n;

  const handleRoute = (route) => {
    router.push(route);
  };

  return (
    <div
      className={`${router.pathname === '/game_website' && 'hidden'}`}
      // className={`z-40 ${router.pathname === '/game_website' && 'hidden'}`}
    >

      <div className='flex gap-3 justify-center mt-2 mb-4'>
        {
          socialMediaList?.map((x) => (
            <Link key={x.type} href={x.link}>
              <a target='_blank' rel='noopener noreferrer'>
                <div>
                  <Image src={x.icon} alt='' />
                </div>
              </a>
            </Link>
          ))
        }
      </div>
      <div className='bg-footer pt-5 md:pt-10 pb-16 md:pb-6 px-8'>
        <Image alt='ppx7' src={logoIcon} width={128} height={45} />

        <div className='grid md:grid-cols-12 gap-x-4 gap-y-8 md:gap-y-0 pb-6'>
          <div className='md:col-span-5'>
            <div className='flex'>
              <div>
                <p className='text-2xs'>
                  {t('home.footerText')}
                </p>
              </div>
            </div>
          </div>
          <div className='md:col-span-7'>
            <div className='grid md:grid-cols-3 gap-x-2 gap-y-8 md:gap-y-0'>
              <div>
                <div
                  style={{ borderBottom: 'solid #8E8E8E6E', width: 150 }}
                  className='md:text-sm pb-2'
                >
                  {t('home.games')}
                </div>
                <div className='flex gap-4'>
                  <ul className='mt-4'>
                    <li 
                      className='cursor-pointer text-xs md:text-2xs text-light-black mb-2'
                      onClick={() => handleRoute('/partner-guidelines')}
                    >
                      {t('common.partnerGuidelines')}
                    </li>
                    <li 
                      className='cursor-pointer text-xs md:text-2xs text-light-black'
                      onClick={() => handleRoute('/privacy-policy')}
                    >
                      {t('common.privacyPolicy')}
                    </li>
                  </ul>
                  <ul className='mt-4'>
                    <li
                      className='cursor-pointer text-xs md:text-2xs text-light-black mb-2'
                      onClick={() => handleRoute('/slots')}
                    >
                      {t('game.slots')}
                    </li>
                    <li
                      className='cursor-pointer text-xs md:text-2xs text-light-black'
                      onClick={() => handleRoute('/instants')}
                    >
                      {t('home.games')}
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div
                  style={{ borderBottom: 'solid #8E8E8E6E', width: 100 }}
                  className='cursor-pointer md:text-sm pb-2'
                >
                  {t('home.promotions')}
                </div>
                <ul className='mt-4'>
                  <li
                    className='cursor-pointer text-xs md:text-2xs text-light-black mb-2'
                    onClick={() => handleRoute('/promotions')}
                  >
                    {t('common.activities')}
                  </li>
                </ul>
              </div>
              <div>
                <div
                  style={{ borderBottom: 'solid #8E8E8E6E', width: 50 }}
                  className='md:text-sm pb-2'
                >
                  {t('home.help')}
                </div>
                <ul className='mt-4'>

                  <li 
                    className='cursor-pointer text-xs md:text-2xs text-light-black mb-2'
                    onClick={() => handleRoute('/cookies-policy')}
                  >
                    {t('common.cookiesPolicy')}
                  </li>

                  <li
                    className='cursor-pointer text-xs md:text-2xs text-light-black'
                    onClick={() => handleRoute('/faq')}
                  >
                    {t('common.faq')}
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-4 text-center'>
          {
            footerProviders.map((x, i) => (
              <div key={i} className='inline-block mr-6 mb-4' style={{ maxWidth: 70 }}>
                <Image src={x.icon} alt='ppx7' />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default Footer;
