import React, { useState } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';

function SideMenuDropdown({
  menuList,
  hideSidebar,
  left,
  right,
}) {
  const activeSelected = useSelector((state) => state.setting.selected);
  const [menuSelected, setMenuSelected] = useState(null);

  return (
    <div>
      {menuList.map((i, index) => (
        <div
          key={index}
          className='sidebar-menu-2 mx-4 my-2'
          // onClick={(e) => (i.type === '' ? i.action() : e.preventDefault())}
        >
          <div
            className={`${hideSidebar ? 'pl-1 pr-1' : 'flex pl-2 pr-3'} items-center justify-between parents py-2.5`}
            onClick={() => {
              i.subItems
                ? menuSelected === index
                  ? setMenuSelected(null)
                  : setMenuSelected(index)
                : i.action();
            }}
          >
            <div className='flex items-center'>
              <div className='inline-block leading-none flex items-center' style={{ width: 30 }}>
                <Image src={i.icon} alt={i.label} />
              </div>

              <div
                className={`inline-block leading-none transition-all duration-200 delay-150 ${hideSidebar ? 'hidden' : 'ml-2'}`}
              >
                <div className='flex items-center'>
                  <span className='font-semibold capitalize mr-1 text-tiny'>
                    {i.label}
                  </span>
                </div>
              </div>
            </div>

            {(!hideSidebar && i.subItems) && (
              <div className='inline-block'>
                <div className='text-end '>
                  {menuSelected !== index && (
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='12'
                        height='12'
                        fill='currentColor'
                        className='bi bi-chevron-down'
                        style={{ display: 'initial' }}
                        viewBox='0 0 16 16'
                      >
                        <path
                          fillRule='evenodd'
                          d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                        />
                      </svg>
                    </span>
                  )}
                  {menuSelected === index && (
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='12'
                        height='12'
                        fill='currentColor'
                        className='bi bi-chevron-up'
                        style={{ display: 'initial' }}
                        viewBox='0 0 16 16'
                      >
                        <path
                          fillRule='evenodd'
                          d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'
                        />
                      </svg>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={`child ${(i.subItems?.length != 0) & (menuSelected === index) && 'active-child'}`}>
            {i.subItems && (
              <>
                {i.subItems.map((item, index) => (
                  <div
                    className={`flex items-center ${hideSidebar ? 'justify-center pt-1 pb-3.5' : 'py-3 pl-6'}`}
                    key={index}
                    onClick={() => {
                      item.action();
                      setMenuSelected(null);
                    }}
                  >
                    <div className={`inline-block leading-none ${!hideSidebar && 'mr-2'}`} style={{ width: hideSidebar ? 'auto' : 20 }}>
                      <Image src={item.icon} alt={item.label} />
                    </div>
                    {
                      !hideSidebar
                    && (
                      <div
                        className='inline-block w-4/5 text-2xs'
                      >
                        {item.label}
                      </div>
                    )
                    }
                  </div>
                ))}
              </>
            )}
          </div>

        </div>
      ))}
    </div>
  );
}

export default SideMenuDropdown;
