const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('../constants');

const getActiveBonus = (params) => axios
.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/summ/gamelog/bonusCheck`,
  headers: {
    Authorization: `Bearer ${
      Cookies.get('accessToken') || params?.accessToken
      }`,
    },
    params,
  })
  .then((response) => response?.data)
  .catch((error) => error);

module.exports = {
  getActiveBonus,
};