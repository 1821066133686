/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef, useContext, useEffect, useState,
} from 'react';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { i18n, useTranslation } from 'next-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import Link from 'next/link';
import {
  PlusOutlined, UserOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import { Button, Tooltip } from 'antd';
import { Turn as Hamburger } from 'hamburger-react';
import LoadingModal from '../dialog/LoadingModal';
import AlertModal from '../dialog/AlertModal';
import { Verify } from '../../api/auth';
import { SettingActions } from '../reducers/settingReducer';
import { getBalance } from '../../api/getBalance';
import { getBonusLimit } from '../../api/getBonusLimit';
import ContactUsModal from '../dialog/contactUsModal';
import Footer from './Footer';
import {
  logoIcon,
} from '../../images';
import RegisterModal from '../dialog/RegisterModal';
// import { Logout } from '../../api/logout';
import LoginComponent from './LoginComponent';
import NavbarBottom from './NavbarBottom';
// import MenuSection from './MenuSection';
import LoginModal from '../dialog/LoginModal';
import { LayoutContext } from '../contexts/LayoutContextContainer';
import Sidebar from './Sidebar';
import { sidebarSocialMediaList } from '../constant/sidebarSocialMediaList';
// import ChangeLanguageButton from '../changeLanguageButton';
import { depositCurrency } from '../../api/currency';
import { formatAmount } from '../common/common';
import PopupGamiModal from '../dialog/PopupGamiModal';
import usePreviousRoute from './usePreviousRoute';
import FooterSeoBr from './FooterSeoBr';
import FooterSeoEn from './FooterSeoEn';
import { CANONICAL_URL } from '../../api/constants';

const LIVECHATURL = 'https://www.ymsza2.com/chat/text/chat_0s0lwc.html';

function Layout(props) {
  const { language } = i18n;
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'Casino',
    name: 'PPX7',
    url: 'https://www.ppx7.com',
    logo: 'https://www.ppx7.com/logo.png',
    description: 'PPX7 | Melhores Jogos de Cassino Online Brasil',
  };
  const { setPromoNotif } = useContext(LayoutContext);

  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const router = useRouter();
  const { referrer } = router.query;
  const isLoading = useSelector((state) => state.setting.loading);
  const isAlert = useSelector((state) => state.setting.alert);
  const [timer, setTimer] = useState(null);
  const [logout, setLogout] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [isRelogin, setIsRelogin] = useState(false);
  const [menuSelected, setMenuSelected] = useState(null);
  const ref = useRef(null);
  const firstLoad = useRef(true);
  const loginShow = useSelector((state) => state.setting.loginShow);
  const { balance } = useSelector((state) => state.setting);
  const isMobile = useSelector((state) => state.setting.isMobile);
  const isFullScreen = useSelector((state) => state.setting.isFullScreen);
  const isLogin = Cookies.get('accessToken');
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCustomLiveChatModal, setShowCustomLiveChatModal] = useState(false);
  const [height, setHeight] = useState(0);
  const [currentUrl, setCurrentUrl] = useState(router.pathname);

  const [hideSidebar, setHideSidebar] = useState(true);
  const windowSize = useSelector((state) => state.setting.windowSize);
  const [sidemenuTab, setSidemenuTab] = useState(0);

  const elementWindow = typeof window !== 'undefined'
    && (document.getElementById('page-container') || null);
  const lastScrollY = elementWindow?.scrollTop || 0;
  const [showPopupGami, setShowPopupGami] = useState(false);
  const previousRoute = usePreviousRoute();

  useEffect(() => {
    dispatch(SettingActions.setSideBar(hideSidebar));
  }, [hideSidebar]);

  useEffect(() => {
    setCurrentUrl(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    if (router.pathname === previousRoute) {
      setTimeout(() => {
        dispatch(SettingActions.setLoading(false));
      }, 500);
    }
  }, [router]);

  useEffect(() => {
    if (router.pathname === '/') {
      const timer = setTimeout(() => {
        if (!isLogin || (balance !== null && Number(balance) === 0 && !isNaN(balance))) {
          setShowPopupGami(true);
        }
      }, 2300);
      return () => clearTimeout(timer);
    }
  }, [balance, router.pathname]);

  // useEffect(() => {
  //   const onScroll = () => {
  //     const nav = document.getElementById('header-navbar');
  //     const leftSider = document.getElementById('left-sider');

  //     /**
  //      * Scroll Down
  //      */
  //     if (lastScrollY < elementWindow?.scrollTop) {
  //       if (elementWindow.scrollTop > 58) {
  //         nav?.classList.add('header-navbar-hidden');
  //         leftSider?.classList.add('leftSider-padding');
  //       }
  //     }
  //     /**
  //      * Scroll Up
  //      */
  //     else {
  //       if (elementWindow.scrollTop <= 57) {
  //         nav?.classList.remove('header-navbar-hidden');
  //         leftSider?.classList.remove('leftSider-padding');
  //       }
  //       // navblock.style.display = 'block';
  //     }

  //     lastScrollY = elementWindow.scrollTop;
  //   };
  //   // clean up code
  //   elementWindow?.removeEventListener('scroll', onScroll);
  //   elementWindow?.addEventListener('scroll', onScroll, { passive: true });
  //   return () => elementWindow?.removeEventListener('scroll', onScroll);
  // }, [elementWindow]);

  useEffect(() => {
    if (!isMobile) dispatch(SettingActions.setIsFullScreen(false));
  }, [isMobile]);

  useEffect(() => {
    function handleResize() {
      let orientation = 'landscape';
      if (window.innerHeight > window.innerWidth) {
        orientation = 'portrait';
      }
      dispatch(
        SettingActions.setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          orientation,
        }),
      );
      dispatch(SettingActions.setIsMobile(window.innerWidth <= 768));
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;

      for (let i = 0; i <= 3; i++) {
        if (height <= 0) {
          setTimeout(() => {
            if (ref?.current?.clientHeight) {
              setHeight(ref.current.clientHeight);
            }
          }, 500);
        } else {
          break;
        }
      }
    } else if (ref?.current?.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
  });

  useEffect(() => {
    if (loginShow) {
      setShowModal(false);
    }
  }, [loginShow]);

  useEffect(() => {
    if (!isLogin) {
      if (referrer) {
        const referrerObj = {
          expiryDate: new Date(
            new Date(new Date().setDate(new Date().getDate() + 30)),
          ),
          referrerCode: referrer,
        };
        // local storage
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.setItem('referrer', JSON.stringify(referrerObj));
        }
        // cookies
        Cookies.set('referrer', JSON.stringify(referrerObj));
        dispatch(SettingActions.setRegisterShow(true));
      }
    }
  }, [referrer]);

  const toggleContactModal = () => {
    setContactShow(!contactShow);
  };

  useEffect(() => {
    clearTimeout(timer);
    const newTimer = setTimeout(async () => {
      verifyToken();
    }, 1000);

    setTimer(newTimer);
  }, [isLogin]);

  const getBal = () => getBalance()
    .then((res) => {
      dispatch(SettingActions.setAccountLoading(false));
      dispatch(SettingActions.setBalance(res?.data?.balance));
    })
    .catch((err) => {
      dispatch(SettingActions.setAccountLoading(false));
      // console.log({ err });
    });

  const verifyToken = async () => {
    if (isLogin) {
      const result = await Verify({ accessToken: isLogin });
      if (result?.data === 'Authenticated') {
        setLogout(false);
        getBal();
      } else {
        // NotAuthenticated: You have been logged out, please login again
        if (result?.includes('NotAuthenticated')) {
          setIsRelogin(true);
        }
        setLogout(true);
      }
    }
  };

  const toggleShowCustomLiveChatModal = () => {
    setShowCustomLiveChatModal(!showCustomLiveChatModal);
  };

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isLoading]);

  useEffect(() => {
    if (logout) {
      Cookies.remove('accessToken');
      Cookies.remove('userId');
      Cookies.remove('username');
      Cookies.remove('fullName');
      if (isRelogin) {
        router.push('/');
        router.reload();
      }
    }
  }, [logout, isRelogin]);

  useEffect(() => {
    if (!isLogin) {
      const allowedRoutes = [
        '/',
        '/promotions',
        '/games',
        '/faq',
        '/login',
        '/register',
        '/contact-us',
        '/forgot-password',
        '/terms-and-condition',
        '/[category]',
        '/game_website',
      ];

      if (
        !allowedRoutes.some(
          (route) => router.route === route || router.route.includes(route),
        )
      ) {
        router.push('/');
        dispatch(SettingActions.setLoginShow(true));
      }
    }
  }, [isLogin]);

  useEffect(() => {
    if (!isLogin) {
      if (router.pathname === '/login') {
        dispatch(SettingActions.setLoginShow(true));
      } else if (router.pathname === '/register') {
        dispatch(SettingActions.setRegisterShow(true));
      } else if (router.pathname === '/contact-us') {
        setContactShow(true);
      }
    }
  }, [router.pathname]);

  useEffect(() => {
    const hideSidebarList = [
      '/game_website',
    ];

    let hideBar = true;
    if (!isMobile) {
      hideBar = false;
    }
    if (
      (hideSidebarList.some(
        (route) => router.route === route || router.route.includes(route),
      )) || isMobile
    ) {
      hideBar = true;
    }
    setHideSidebar(hideBar);
  }, [router.pathname, isMobile]);

  useEffect(() => {
    const handleBonusLimit = async () => {
      const result = await getBonusLimit({
        branchId: process.env.BRANCH_ID,
      });
      setPromoNotif(result.data?.count || 0);
    };
    handleBonusLimit();
  }, [process.env.BRANCH_ID]);

  return (
    <>
      <Head>
        <script
          type='application/Id+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Head>
      <div
        className='hidden-scrollbar theme-bg'
      >
        <div
          className='relative h-screen overflow-hidden flex'
        >
          <Sidebar
            sidemenuTab={sidemenuTab}
            setSidemenuTab={setSidemenuTab}
            hideSidebar={hideSidebar}
            setHideSidebar={setHideSidebar}
            setMenuSelected={setMenuSelected}
          />

          <div
            id='page-container'
            className='col-span-10 h-screen overflow-y-auto hidden-scrollbar relative w-full'
            style={{ boxShadow: 'rgba(142, 142, 142, 0.43) -1px 0px 0px 0px' }}
          >
            <div id='top'>
              {
                !isFullScreen
                && (
                  <div
                    id='header-navbar'
                    className='z-10'
                  >
                    <div className='flex items-center h-[4.02rem] justify-between bg-dark'>
                      <div className='flex items-center'>
                        <div className='leading-unset cursor-pointer' onClick={() => setHideSidebar(!hideSidebar)}>
                          <Hamburger size={isMobile ? 20 : 26} toggled={false} />
                        </div>
                        <div className='main-menu-logo cursor-pointer md:ml-4 leading-unset'>
                          <Link href='/'>
                            <Image
                              alt='PPX7'
                              onClick={() => {
                                dispatch(SettingActions.setLoading(true));
                                router.push('/');
                              }}
                              src={logoIcon}
                            />
                          </Link>
                        </div>
                      </div>

                      <div className='mr-2'>
                        {isLogin ? (
                          <div className='flex justify-end items-center flex-wrap'>
                            <Link href='/deposit'>
                              <div
                                className='mr-1 rounded p-1 flex items-center cursor-pointer bg-default'
                              >
                                <div className='px-2'>
                                  <div className='font-semibold text-sm tracking-tight opacity-70'>
                                    {formatAmount(balance)}
                                  </div>
                                </div>
                                <div
                                  className='rounded-full flex justify-center align-center items-center'
                                  style={{
                                    backgroundColor: '#22A35F',
                                    width: 22,
                                    height: 22,
                                  }}
                                >
                                  <PlusOutlined
                                    style={{
                                      color: 'white',
                                      fontSize: 14,
                                    }}
                                  />
                                </div>
                              </div>
                            </Link>
                            <Link href='/profile'>
                              <div
                                className='mr-1 cursor-pointer flex rounded-full items-center justify-center inner-shadow'
                                style={{
                                  backgroundColor: '#22A35F',
                                  width: 30,
                                  height: 30,
                                }}
                              >
                                <UserOutlined
                                  style={{
                                    color: 'white',
                                    fontSize: 14,
                                  }}
                                />
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <LoginComponent />
                        )}
                      </div>
                    </div>
                  </div>
                )
              }

              <div className='flex flex-col justify-between'>
                {/* <LiveChat
                  setShowCustomLiveChatModal={setShowCustomLiveChatModal}
                  showCustomLiveChatModal={showCustomLiveChatModal}
                /> */}

                <div className='relative mx-auto w-full'>
                  <div
                    className='mb-auto h-full'
                  >
                    <div className='h-full'>

                      <div className='container'>
                        <div className='relative'>{props.children}</div>

                        {
                          (currentUrl.includes('/faq') || currentUrl.includes('/terms-and-condition')) && (
                            <div className='ml-2'>
                              <Button
                                className='button-secondary py-1.5 md:py-2.5 shadow px-3 md:px-4'
                              >
                                <div className='flex items-center justify-center'>
                                  <a className='md:font-bold font-semibold text-xs md:text-sm uppercase' href={CANONICAL_URL}>
                                    PPX7
                                    {' '}
                                    {t('common.home')}
                                  </a>
                                </div>
                              </Button>
                            </div>
                          )
                        }

                        {(!/^\/news\/.+/.test(router.pathname) && router.pathname !== '/game_website') && (
                          language === 'en-BR' ? <FooterSeoEn /> : <FooterSeoBr />
                        )}

                      </div>

                    </div>
                  </div>
                </div>
                {/* end header */}
                {(!/^\/news\/.+/.test(router.pathname) && router.pathname !== '/game_website') && (
                  <Footer setContactShow={setContactShow} />
                )}

              </div>
            </div>
          </div>
        </div>
      </div>

      {
        router.pathname !== '/game_website'
        && (
          <div className='fixed right-0 z-10' style={{ top: '50%' }}>

            <div className='flex flex-col gap-2 px-4'>

              {sidebarSocialMediaList.map((item, index) => {
                if (item.text === 'Telegram') {
                  return (
                    <Tooltip placement='left' title={item.text}>
                      <div
                        className='cursor-pointer relative'
                        onClick={item.action}
                        key={`${item.text}-${index}`}
                        style={{
                          width: 50,
                        }}
                      >
                        <Image src={item.icon} />
                      </div>
                    </Tooltip>
                  );
                } if (item.text === 'Live Chat') {
                  return (
                    <Tooltip placement='left' title={item.text}>
                      <div
                        className='cursor-pointer relative'
                        onClick={() => { setShowCustomLiveChatModal(true); }}
                        key={`${item.text}-${index}`}
                        style={{
                          width: 50,
                        }}
                      >
                        <Image src={item.icon} />
                      </div>
                    </Tooltip>
                  );
                } if (item.text === 'Free Credit') {
                  return (
                    <Tooltip placement='left' title={item.text}>
                      <div
                        className='cursor-pointer relative'
                        onClick={() => {
                          setShowPopupGami(true);
                        }}
                        key={`${item.text}-${index}`}
                        style={{
                          width: 50,
                        }}
                      >
                        <Image src={item.icon} />
                      </div>
                    </Tooltip>
                  );
                } if (item.text === 'Lucky Spin') {
                  return (
                    <Tooltip placement='left' title={item.text}>
                      {isLogin ? (
                        <Link href={`/${item.url}`}>
                          <div
                            className='cursor-pointer relative'
                            key={`${item.text}-${index}`}
                            style={{ width: 50 }}
                            onClick={() => {
                              dispatch(SettingActions.setLoading(true));
                            }}
                          >
                            <Image src={item.icon} />
                          </div>
                        </Link>
                      ) : (
                        <div
                          className='cursor-pointer relative'
                          onClick={() => dispatch(SettingActions.setLoginShow(true))}
                          key={`${item.text}-${index}`}
                          style={{ width: 50 }}
                        >
                          <Image src={item.icon} />
                        </div>
                      )}
                    </Tooltip>
                  );
                }
                return (
                  <Tooltip placement='left' title={item.text}>
                    <div
                      className='cursor-pointer relative'
                      onClick={() => {
                        dispatch(SettingActions.setLoading(true));
                        router.push(item.url);
                      }}
                      key={`${item.text}-${index}`}
                      style={{
                        width: 50,
                      }}
                    >
                      <Image src={item.icon} />
                    </div>
                  </Tooltip>
                );
              })}

            </div>

          </div>
        )
      }

      {/* LIVE CHAT */}
      {showCustomLiveChatModal ? (
        <div
          className={`right-1 fixed ${windowSize?.orientation === 'landscape' && isMobile
            ? 'bottom-0 livechat-custom-landscape'
            : 'md:bottom-20 bottom-10 livechat-custom'
          }`}
          style={{ zIndex: 99 }}
        >
          <div
            className='px-4 flex justify-between items-center bg-dark'
            style={{
              height: '8%',
            }}
          >
            <span className='-mt-1 inline-block w-24 ml-0.5'>
              <Image alt='Live Casino Brazil' src={logoIcon} />
            </span>
            <Button onClick={() => setShowCustomLiveChatModal(false)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                fill='grey'
                className='bi bi-dash'
                viewBox='0 0 16 16'
              >
                <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' />
              </svg>
            </Button>
          </div>
          <div style={{ height: '80%' }}>
            <iframe
              scrolling='no'
              style={{ overflow: 'hidden' }}
              id='betleon-livechat'
              className='w-full h-full'
              src={LIVECHATURL}
            />
          </div>
        </div>
      ) : null}

      {/* modals */}
      <div className=''>

        <ContactUsModal show={contactShow} toggleModal={toggleContactModal} />

        {router.pathname !== '/game_website' && (
          <NavbarBottom
            show={showModal}
            setShowModal={setShowModal}
            toggleShowCustomLiveChatModal={toggleShowCustomLiveChatModal}
            setShow={setShow}
            setHideSidebar={setHideSidebar}
            hideSidebar={hideSidebar}

          />
        )}

        {isLoading && <LoadingModal />}

        {isAlert.length > 0 && (
          <AlertModal message={isAlert[0]?.msg} action={isAlert[1]} />
        )}

        <PopupGamiModal
          show={showPopupGami}
          toggleShow={setShowPopupGami}
        />
        <LoginModal />
        <RegisterModal />
      </div>
    </>
  );
}

export default Layout;
