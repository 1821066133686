import {
  socialMediaLiveChat,
  socialMediaTelegram,
  socialMediaFreeR5,
  socialMediaLuckySpin,
} from '../../images';

export const sidebarSocialMediaList = [
  {
    icon: socialMediaLuckySpin,
    url: 'spinwheel',
    text: 'Lucky Spin',
  },
  {
    icon: socialMediaFreeR5,
    url: 'daily-free-credit',
    text: 'Free Credit',
  },
  {
    icon: socialMediaTelegram,
    action: () => {
      window.open('https://t.me/ppx7brazil', '_blank');
    },
    text: 'Telegram',
  },
  {
    icon: socialMediaLiveChat,
    text: 'Live Chat',
  },
];
