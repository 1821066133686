const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const getBonusLimit = (data) => axios
  .request({
    method: 'GET',
    url: `${ENDPOINT}/v1/bonusLimit`,
    params: data,
  })
  .then((response) => response)
  .catch((error) => error);

module.exports = {
  getBonusLimit,
};
