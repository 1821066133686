import React, { useLayoutEffect } from 'react';
// import { motion } from 'framer-motion';
import Image from 'next/image';
// import { useSelector, useDispatch } from 'react-redux';
import {
  logoIcon,
} from '../../images';
// import { SettingActions } from '../reducers/settingReducer';

export default function App() {
  // const dispatch = useDispatch();
  // const isLoading = useSelector((state) => state.setting.loading);

  // useLayoutEffect(() => {
  //   if (isLoading) {
  //     setTimeout(() => {
  //       dispatch(SettingActions.setLoading(false));
  //     }, 1500);
  //   }
  // }, []);

  return (
    <div>
      <div
        tabIndex='-1'
        className='overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-[100] md:inset-0 h-modal h-full justify-center items-center flex'
        style={{ backgroundColor: 'rgba(15, 25, 35, .9)' }}
        role='dialog'
      >
        <div>
          <div className='md:hidden m-auto' style={{ width: 35 }}>
            <Image src={logoIcon} alt='Betting sites in Brazil' />
          </div>
          <div className='md:block hidden m-auto' style={{ width: 40 }}>
            <Image src={logoIcon} alt='Betting sites in Brazil' />
          </div>
          <span className='loader' />
        </div>
      </div>
    </div>
  );
}
