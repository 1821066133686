import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { changeLocale } from '../common/general';
import { Tooltip, Button } from 'antd';
import useSound from 'use-sound';
import create from '@ant-design/icons/lib/components/IconFont';
import moment from 'moment';
import {
  GamificationOpened1,
  GamificationOpened2,
  GamificationOpened3,
  GamificationOpened4,
  GamificationCoinfront1,
  GamificationCoinfront2,
  GamificationCoinfront3,
  GamificationCoinfront4,
} from '../../images';
import { handleClick$5 } from '../common/common';
import { getBonusClaim } from '../../api/promotions/getBonusClaim';
import { getBonusOne } from '../../api/getBonus';
import BonusModal from '../dialog/BonusModal';

function FreeCreditComponents() {
  const isMobile = useSelector((state) => state.setting.isMobile);
  const [showSurprise, setShowSurprise] = useState(false);
  const [isStage2, setIsStage2] = useState(false);
  const [play] = useSound('Daily Free Credit SFX.mp3');
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(Cookies.get('accessToken'));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [activeBonusClaim, setActiveBonusClaim] = useState(false);
  const [bonusDetails, setBonusDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation('common');

  const getActiveBonusClaim = async () => {
    const BonusDetails = await getBonusOne({
      permalink: 'R$5-free-bet',
    });

    if (BonusDetails?.data?.data[0]) {
      setBonusDetails(BonusDetails.data.data[0]);

      const result = await getBonusClaim({
        bonusId: BonusDetails.data.data[0]?._id,
        branchId: process.env.BRANCH_ID,
        userId: Cookies.get('userId'),
        '$sort[createdAt]': -1,
        $limit: 1,
        'createdAt[$gt]': moment().startOf('day').toDate(),
      });

      if (result?.total > 0) {
        setActiveBonusClaim(true);
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      getActiveBonusClaim();
    }
  }, [isLogin]);

  const handleClick = (isLogin, isOpen, setIsOpen, router, dispatch) => {
    let accessToken = isLogin;
    if (!isLogin) {
      accessToken = Cookies.get('accessToken');
      setIsLogin(accessToken);
    }

    if (isLogin && activeBonusClaim) {
      setIsButtonDisabled(true);

      // Wait for 10 seconds before re-enabling the button
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 10000);
    }
    const bonusID = bonusDetails?._id;
    handleClick$5(bonusID, isLogin || accessToken, isOpen, setIsOpen, router, dispatch, t);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowSurprise(true);
        play();
      }, 600);
      setTimeout(() => {
        setIsStage2(true);
      }, 1000);
    } else {
      setShowSurprise(false);
      setIsStage2(false);
    }
  }, [isOpen]);

  return (
    <>
      <div className='flex items-center justify-center'>
        {
          isOpen
            ? <h2 className='font-bold text-2xl mr-1.5'>{t('freeCredit.congratsYouGot')}</h2>
            : (
              <>
                <h2 className='font-bold text-2xl uppercase mr-1.5'>{t('freeCredit.dailyFreeCredit')}</h2>
                {
                  isLogin
                    ? (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        fill='#0BBF7B'
                        className='bi bi-exclamation-circle cursor-pointer'
                        viewBox='0 0 16 16'
                        onClick={(e) => {
                          e.preventDefault();
                          setShowModal(true);
                        }}
                      >
                        <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16' />
                        <path d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z' />
                      </svg>
                    )
                    : null
                }
              </>
            )
        }
        {showModal && (
          <BonusModal
            show={showModal}
            toggleShow={setShowModal}
            activeBonus={bonusDetails}
            terms={t('common.termsConditions')}
            noHTML
          />
        )}
      </div>

      <div
        className={`-mt-10 md:mt-0 relative gamification-wrapper ${isOpen && 'gamification-wrapper-open'}`}
        style={{ transform: isMobile ? 'scale(.8)' : 'scale(1)' }}
      >
        {
          (isOpen && isStage2)
            ? (
              <div className='absolute z-10 left-0 right-0 m-auto max-w-xl'>
                {
                  [1, 2, 3, 4, 5, 6].map((x, i) => {
                    const getRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
                    const randomDuration = `${getRandomValue(1, 5)}s`; // Random duration between 1s and 5s

                    const containerWidth = 500;
                    const containerHeight = 300;
                    const elementSize = 50; // Width and height of the element

                    // Ensure the element fits within the container
                    const randomTop = getRandomValue(0, containerHeight - elementSize);
                    const randomLeft = getRandomValue(0, containerWidth - elementSize);

                    return (
                      <div
                        key={x}
                        className='star-svg'
                        style={{
                          '--duration': randomDuration, '--delay': `${i}s`, top: randomTop, left: randomLeft,
                        }}
                      />
                    );
                  })
                }
              </div>
            ) : (
              <div className='absolute z-10 left-0 right-0 m-auto max-w-xl'>
                {
                  [1, 2, 3, 4].map((x, i) => {
                    const getRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
                    const randomDuration = `${getRandomValue(1, 5)}s`; // Random duration between 1s and 5s

                    const containerWidth = 500;
                    const containerHeight = 100;
                    const elementSize = 50; // Width and height of the element

                    // Ensure the element fits within the container
                    const randomTop = getRandomValue(0, containerHeight - elementSize);
                    const randomLeft = getRandomValue(0, containerWidth - elementSize);

                    return (
                      <div
                        key={x}
                        className='star-svg'
                        style={{
                          '--duration': randomDuration, '--delay': `${i}s`, top: randomTop, left: randomLeft,
                        }}
                      />
                    );
                  })
                }
              </div>
            )
        }
        <div style={{ maxWidth: 350 }} className={`relative z-[5] m-auto gamification-open-cap ${isOpen && 'open-cap'}`}>
          <Image src={GamificationOpened2} alt='ppx7' />
        </div>
        <div style={{ maxWidth: 350 }} className='relative z-[4] m-auto'>
          <Image src={GamificationOpened1} alt='ppx7' />
        </div>
        {
          isOpen && (
            <div
              style={{
                maxWidth: 260,
              }}
              className={`absolute left-0 right-0 m-auto z-[2] gamification-open-inner ${isOpen && 'open-inner'}`}
            >
              <Image loading='lazy' src={GamificationOpened3} alt='ppx7' />
              <div style={{
                position: 'absolute',
                top: isMobile ? '26%' : '25%',
                left: 0,
                right: 0,
                margin: 'auto',
                textAlign: 'center',
              }}
              >
                <h2 className='text-5xl font-bold leading-none mb-2'>R$5</h2>
                <p className='text-2xs font-normal'>
                  {t('freeCredit.exclusiveFreeCredit')}
                  <br />
                  {t('freeCredit.pgsoftSlots')}
                </p>
              </div>
            </div>
          )
        }

        {
          isOpen && (
            <div
              style={{
                maxWidth: 150,
              }}
              className={`absolute left-0 right-0 m-auto z-[1] gamification-open-inner-1 ${isOpen && 'open-inner-1'}`}
            >
              <Image loading='lazy' src={GamificationOpened4} alt='ppx7' />
            </div>
          )
        }

        {
          showSurprise
          && (
            <video autoPlay playsInline width='400' className={`z-10 gamification-open-inner-2 ${isOpen && 'open-inner-2'}`}>
              <source src='/Animation.webm' type='video/webm' />
              {t('freeCredit.browserDoesntSupport')}
            </video>
          )
        }

        <div>
          <div
            style={{
              maxWidth: 65,
            }}
            className={`absolute left-0 right-0 m-auto z-[5] gamification-open-inner-coin-1 ${isOpen && 'open-inner-coin-1'} ${isStage2 && 'open-inner-coin-1-stage-2'}`}
          >
            <Image src={GamificationCoinfront1} alt='ppx7' />
          </div>
          <div
            style={{
              maxWidth: 150,
            }}
            className={`absolute left-0 right-0 m-auto z-[5] gamification-open-inner-coin-2 ${isOpen && 'open-inner-coin-2'} ${isStage2 && 'open-inner-coin-2-stage-2'}`}
          >
            <Image src={GamificationCoinfront2} alt='ppx7' />
          </div>
          <div
            style={{
              maxWidth: 310,
            }}
            className={`absolute left-0 right-0 m-auto z-[5] gamification-open-inner-coin-3 ${isOpen && 'open-inner-coin-3'} ${isStage2 && 'open-inner-coin-3-stage-2'}`}
          >
            <Image src={GamificationCoinfront3} alt='ppx7' />
          </div>
          <div
            style={{
              maxWidth: 40,
            }}
            className={`absolute left-0 right-0 m-auto z-[1] gamification-open-inner-coin-4 ${isOpen && 'open-inner-coin-4'} ${isStage2 && 'open-inner-coin-4-stage-2'}`}
          >
            <Image src={GamificationCoinfront4} alt='ppx7' />
          </div>

        </div>

        <div className='m-auto text-center relative z-2 mb-2 md:mt-4'>
          {isOpen
            ? (
              <Button
                className='py-2 lg:py-3 px-6 !rounded-full button-primary '
                onClick={() => handleClick(isLogin, isOpen, setIsOpen, router, dispatch)}
              >
                <p className='text-black font-bold text-2xs lg:text-xs uppercase opacity-60'>
                  {t('freeCredit.playNow')}
                </p>
              </Button>
            )
            : (
              <Button
                className={`py-2 lg:py-3 px-6 !rounded-full mt-3 md:mt-0 lg:mt-0 ${isButtonDisabled || activeBonusClaim ? 'bg-gray-400' : 'button-primary '} `}
                onClick={() => handleClick(isLogin, isOpen, setIsOpen, router, dispatch)}
                disabled={isButtonDisabled || activeBonusClaim}
              >
                <p className='text-black font-bold text-lg uppercase opacity-60'>
                  {t('freeCredit.claimNow')}
                </p>
              </Button>
            )}
        </div>
      </div>
    </>
  );
}

export default FreeCreditComponents;
