const { ENDPOINT } = process.env;
const { GAMIKEY } = process.env;
const { GAMISECRET } = process.env;
const { GAMIAPI } = process.env;
const { PRIZEAPI } = process.env;
const { RECAPTCHA_SITEKEY } = process.env;

const ENDPOINT_PAYMENT = 'https://pay.v-pay88.com';
const CANONICAL_URL = 'https://www.ppx7.com';
const AUTHKEY = '6C[D0$+5uK9U|2{izUz';

module.exports = {
  ENDPOINT,
  ENDPOINT_PAYMENT,
  CANONICAL_URL,
  AUTHKEY,
  GAMIKEY,
  GAMISECRET,
  GAMIAPI,
  PRIZEAPI,
  RECAPTCHA_SITEKEY
};
