import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Button } from 'antd';
import { useTranslation } from 'next-i18next';
import { SettingActions } from '../reducers/settingReducer';

export default function App({ message, action }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation('common');
  const [isClose, setIsClose] = useState('');

  const closeModal = () => {
    if (action?.redirectUrl) {
      router.push(action?.redirectUrl);
    }
    dispatch(SettingActions.setAlert([]));
    setIsClose(true);
  };

  return (
    <div
      id='alert-popup-modal'
      tabIndex='-1'
      className='overflow-scroll fixed top-0 right-0 left-0 z-[100] h-modal h-full modal-primary show'
      role='dialog'
    >
      <div
        className='overlay-closeable'
        onClick={() => closeModal()}
      />
      <div className='relative px-5 pt-4 md:pt-8 rounded z-10 modal-alert-dialog'>
        <div className='absolute top-1 right-2 m-auto text-center'>
          <Button
            onClick={() => closeModal()}
            className='close-button relative z-10'
          >
            <CloseOutlined
              fill='white'
              style={{ fontSize: '20px', color: 'white' }}
            />
          </Button>
        </div>
        <div className='mb-4 px-6 text-sm'>
          <div className='header text-center mb-2'>
            <div className='text-lg font-medium'>
              {t('common.alert')}
              !
            </div>
          </div>
          <div className='body text-center'>
            <div className='font-normal mb-4'>{message}</div>
          </div>
          <div className='footer'>
            <Button
              onClick={() => closeModal()}
              className='h-10 button-primary w-full'
            >
              <span className='text-white font-medium uppercase'>
                {t('common.close')}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
