import React, { useState } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';

function SideMenuBackground({
  menuList,
  hideSidebar,
}) {
  const activeSelected = useSelector((state) => state.setting.selected);
  const [menuSelected, setMenuSelected] = useState(null);

  return (
    <div>
      {menuList.map((i, index) => (
        <div
          key={index}
          className={`relative shine-2 sidebar-menu-background mx-4 my-2 ${hideSidebar ? 'py-1' : 'pl-2'}`}
          style={{ backgroundImage: `url(${i.bg.src})` }}
          // onClick={(e) => (i.type === '' ? i.action() : e.preventDefault())}
        >
          <div
            className={`${hideSidebar ? '' : 'grid grid-cols-12'} items-center justify-between parents align-center`}
            onClick={() => {
              i.subItems
                ? menuSelected === index
                  ? setMenuSelected(null)
                  : setMenuSelected(index)
                : i.action();
            }}
          >
            <div
              className={`col-span-7 inline-block leading-tight transition-all duration-200 delay-150 ${hideSidebar ? 'hidden' : 'ml-2'}`}
            >
              <div className='flex items-center align-center'>
                <span className='text-base font-bold capitalize mr-1' style={i?.textStyle}>
                  {i.label}
                </span>
              </div>
            </div>

            <div
              className='col-span-5 inline-block leading-none flex items-center'
              style={{ width: hideSidebar ? 'auto' : 83 }}
            >
              <img src={i.icon.src} alt={i.label} />
            </div>

          </div>
        </div>
      ))}
    </div>
  );
}

export default SideMenuBackground;
