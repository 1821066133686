import React, { useEffect } from 'react';
import { Button } from 'antd';
// import axios from 'axios';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { SettingActions } from '../reducers/settingReducer';
import { Languagesvg } from '../../images';

function LoginComponent() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    dispatch(SettingActions.setLoading(false));
  }, []);

  return (
    <div className='flex items-center w-full gap-1 md:gap-2'>
      <div className='w-full text-end'>
        <Button
          className='button-secondary py-1.5 lg:py-2.5 shadow px-3 md:px-4'
          onClick={() => {
            window.history.replaceState('', '', '/login');
            dispatch(SettingActions.setLoginShow(true));
          }}
        >
          <div className='flex items-center justify-center'>
            <span className='md:font-bold font-semibold text-xs md:text-sm uppercase'>
              {t('login.login1')}
            </span>
          </div>
        </Button>
      </div>
      <div className='md:text-end'>
        <Button
          className='button-primary py-1.5 md:py-2.5 shadow px-3 md:px-4'
          onClick={() => {
            window.history.replaceState('', '', '/register');
            dispatch(SettingActions.setRegisterShow(true));
            // router.push({
            //   pathname: '/register',
            // }, undefined, { shallow: true });
          }}
        >
          <div className='flex items-center justify-center'>
            <span className='md:font-bold font-semibold text-xs md:text-sm uppercase'>
              {t('login.register')}
            </span>
          </div>
        </Button>
      </div>
      {/* <div className='md:text-end flex items-center cursor-pointer'>
        <Button
          className='button-secondary py-2 lg:py-2.5 shadow w-6 lg:w-10'
        >
          <div className='flex items-center justify-center button-language'>
            <Image src={Languagesvg} alt='' />
          </div>
        </Button>
        <p className='text-white font-medium ml-2 text-2xs md:text-normal'>English</p>
      </div> */}
    </div>
  );
}
export default LoginComponent;
