import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavbarHistory,
  NavbarMain,
  NavbarPromo,
  NavbarReferral,
  NavbarVip,
} from '../../images';
import { LayoutContext } from '../contexts/LayoutContextContainer';
import { SettingActions } from '../reducers/settingReducer';

function NavbarBottom({
  show,
  toggleModal,
  setShowModal,
  toggleShowCustomLiveChatModal,
  setShow,
  setHideSidebar,
  hideSidebar,
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const mobileMenuSelected = useSelector(
    (state) => state.setting.mobileMenuSelected,
  );
  const { promoNotif } = useContext(LayoutContext);

  const menu = [
    {
      name: 'home.main',
      img: NavbarMain,
      width: 44,
      action: (i) => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setActiveProviderSelected(null));
        dispatch(SettingActions.setMobileMenuSelected(0));
        router.push('/');
      },
    },
    {
      name: 'home.vip',
      img: NavbarVip,
      width: 32,
      action: (i) => {
        if (Cookies.get('accessToken')) {
          dispatch(SettingActions.setLoading(true));
          dispatch(SettingActions.setActiveProviderSelected(null));
          dispatch(SettingActions.setMobileMenuSelected(1));
          router.push('/vip');
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      name: 'home.promo',
      img: NavbarPromo,
      width: 32,
      notification: promoNotif,
      action: (i) => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setActiveProviderSelected(null));
        dispatch(SettingActions.setMobileMenuSelected(2));
        router.push('/promotions');
      },
    },
    {
      name: 'home.history',
      img: NavbarHistory,
      width: 30,
      action: () => {
        if (Cookies.get('accessToken')) {
          dispatch(SettingActions.setLoading(true));
          dispatch(SettingActions.setSelected(99));
          dispatch(SettingActions.setMobileMenuSelected(3));
          router.push('/history');
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      name: 'home.referral',
      img: NavbarReferral,
      width: 30,
      action: (i) => {
        if (!Cookies.get('accessToken')) {
          dispatch(SettingActions.setLoginShow(true));
        } else {
          dispatch(SettingActions.setMobileMenuSelected(4));
          router.push({
            pathname: '/referral',
          });
        }
      },
    },
    // {
    //   name: 'home.casino',
    //   img: NavbarCasino,
    //   width: 34,
    //   action: () => {
    //     dispatch(SettingActions.setLoading(true));
    //     dispatch(SettingActions.setSelected(3));
    //     dispatch(SettingActions.setMobileMenuSelected(3));
    //     router.push({
    //       pathname: '/casino-main-page',
    //     });
    //   },
    // },
    // {
    //   name: 'home.coming',
    //   img: NavbarSports,
    //   width: 26,
    //   action: async () => {
    //   //   if (Cookies.get('accessToken')) {
    //   //     accessToSport();
    //   //     dispatch(SettingActions.setMobileMenuSelected(1));
    //   //   } else {
    //   //     accessToSport('', true);
    //   //   }
    //   },
    // },
    // {
    //   name: 'Menu',
    //   img: NavbarMenu,
    //   action: (i) => {
    //     // setShow(true);
    //     setShowModal(false);
    //     setHideSidebar(!hideSidebar);
    //     dispatch(SettingActions.setMobileMenuSelected(i));
    //   },
    // },
  ];

  return (
    <div className='navbar-bottom block md:hidden'>
      <div className='navbar-child flex justify-evenly items-end pb-1'>
        {menu.map((x, i) => (
          <div
            key={i}
            className={`relative mb-1 text-center ${mobileMenuSelected === i ? 'nav-active' : 'nav-inactive'
            }`}
            style={{ width: '20%', margin: 'auto' }} // Set width to 20%
            onClick={() => {
              if (x.action) {
                x.action(i);
              } else {
                setShowModal(false);
                dispatch(SettingActions.setMobileMenuSelected(i));
                // dispatch(SettingActions.setLoading(true));
                router.push(x.link);
              }
            }}
          >
            <div className='leading-unset mb-2' style={{ width: x.width || 30 }}>
              <Image src={x.img} alt={x.name} />
            </div>
            {
              ((!Cookies.get('bonusRead')) && x.notification)
              && <div className='navbar-bottom-badge'>{x.notification}</div>
            }
            {x.name && (
              <p
                className='text-sm font-semibold'
              >
                {t(x.name)}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default NavbarBottom;
