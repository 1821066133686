import React, { useState } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';

function SideMenuBackground({
  menuList,
  hideSidebar,
}) {
  const activeSelected = useSelector((state) => state.setting.selected);
  const [menuSelected, setMenuSelected] = useState(null);

  return (
    <div>
      {menuList.map((i, index) => (
        <div
          key={index}
          className='sidebar-menu-2 mx-4 my-2'
          // onClick={(e) => (i.type === '' ? i.action() : e.preventDefault())}
        >
          <div
            className={`${hideSidebar ? 'pl-1 pr-1' : 'flex pl-2 pr-3'} items-center justify-between parents py-1`}
            onClick={() => {
              i.subItems
                ? menuSelected === index
                  ? setMenuSelected(null)
                  : setMenuSelected(index)
                : i.action();
            }}
          >
            <div className={`${!hideSidebar && 'flex'} items-center justify-between w-full`}>
              <div
                className={`inline-block leading-none transition-all duration-200 delay-150 ${hideSidebar ? 'hidden' : 'ml-2'}`}
              >
                <div className='flex items-center'>
                  <span className='font-semibold capitalize mr-1 text-tiny'>
                    {i.label}
                  </span>
                </div>
              </div>

              <div className='inline-block leading-none flex justify-end items-center' style={{ width: hideSidebar ? 'auto' : 38 }}>
                <Image src={i.icon} alt={i.label} />
              </div>

            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SideMenuBackground;
