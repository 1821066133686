// common.js
import { i18n } from 'next-i18next';
import Cookies from 'js-cookie';
import { SubmitDeposit } from '../../api/deposit/submitDeposit';
import { SettingActions } from '../reducers/settingReducer';
import AlertModal from '../dialog/AlertModal';
import { getActiveBonus } from '../../api/promotions/getActiveBonus';

function formatAmount(amount) {
  const { language } = i18n;
  let numberAmount;
  if (isNaN(Number(amount))) {
    numberAmount = Number(0);
  } else {
    numberAmount = Number(amount);
  }
  // return numberAmount.toLocaleString(language, { style: 'currency', currency: language === 'pt-BR' ? 'BRL' : 'USD', minimumFractionDigits: 2 }); // optional
  return numberAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
}

function switchLanguage(locale) {
  switch (locale.language) {
  case 'en-BR':
    return 'en';
  case 'pt-BR':
    return 'bz';
  case 'cn':
    return 'cn';
  case 'my':
    return 'my';
  default:
    return 'bz'; // default language
  }
}

const handleDeposit = async (bonusId) => {
  const result = await SubmitDeposit({
    bonusId,
  });

  return result;
};

const handleClick$5 = async (bonusId, isLogin, isOpen, setIsOpen, router, dispatch, t) => {
  if (!isLogin) {
    dispatch(SettingActions.setLoginShow(true));
  } else if (isOpen) {
    router.push('/slots/pgsoft');
  } else {
    const checking = await handleDeposit(bonusId);
    if (checking?.status === 201) {
      setIsOpen(true);
    } else {
      switch (checking?.response?.data?.message) {
      case 'This bonus only allow for verified profile':
        dispatch(
          SettingActions.setAlert([
            { msg: checking?.response?.data?.message },
            { redirectUrl: '/profile' },
          ]),
        );
        break;
        // case 'User balance above requirement amount : Balance must less than 0.1':
        //   dispatch(
        //     SettingActions.setAlert([
        //       {
        //         msg: 'Wallet must not contain any amount to claim this bonus.',
        //       },
        //     ]),
        //   );
        //   break;
      default:
        dispatch(
          SettingActions.setAlert([{ msg: `${t('freeCredit.errorMsg')}` }]),
        );
        break;
      }
    }
  }
};

module.exports = {
  formatAmount,
  switchLanguage,
  handleClick$5,
};
