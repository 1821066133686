import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    email: '',
    name: '',
    auth: true,
    volume: 1,
    locale: 'en-BR',
  },
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setName: (state, action) => ({
      ...state,
      name: action.payload,
    }),
    setEmail: (state, action) => ({
      ...state,
      email: action.payload,
    }),
    setAuth: (state, action) => ({
      ...state,
      auth: action.payload,
    }),
    setVolume: (state, action) => ({
      ...state,
      volume: action.payload,
    }),
    setLocale: (state, action) => ({
      ...state,
      locale: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
const { reducer: user, actions } = userSlice;
export const UserActions = actions;
export default user;
