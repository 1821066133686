import { CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { PromotionBg, PromotionHeader } from '../../images/index';
import {
  Button,
} from 'antd';
import Cookies from 'js-cookie';
import { SubmitDeposit } from '../../api/deposit/submitDeposit';
import CommonButton from '../common/CommonButton';
import { switchLanguage } from '../common/common';
import { SettingActions } from '../reducers/settingReducer';

export default function App(props, { children }) {
  const router = useRouter();
  const { t, i18n } = useTranslation('common');
  const {
    show, toggleShow, activeBonus, toggleProfileModal, setSuccessFailInfo, setShowSuccessFailModal, noHTML
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const isLoggedIn = Cookies.get('accessToken');
  return (
    <div
      id='modal'
      tabIndex='-1'
      className={
        show
          ? 'overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show'
          : 'overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary'
      }
      role='dialog'
    >
      <div className='overlay-closeable' onClick={() => toggleShow()} />
      <div className='relative px-5 pt-4 md:pt-8 pb-10 rounded-lg z-10 modal-dialog'>
        <div className='absolute -top-6 right-0 left-0 m-auto text-center md:m-0 md:text-end md:-right-4 md:-top-4'>
          <button
            className='rounded-full close-button relative z-10'
            onClick={() => {
              toggleShow();
            }}
          >
            <CloseCircleFilled
              fill='white'
              style={{ fontSize: '35px', color: 'white' }}
            />
          </button>
        </div>
        <div className='head mb-4 mt-4'>
          {activeBonus?.images ? (
            <img
              className='overflow-hidden rounded-lg w-full'
              src={
                activeBonus?.images?.[switchLanguage(i18n)]
              }
              alt='Slot bonus'
            />
          ) : null}
        </div>
        <div className='body mb-4'>
          <div
            className='text-lg font-normal capitalize md:text-lg mb-2'
          >
            <h5>
              {
                activeBonus?.titles?.[switchLanguage(i18n)]
              }
            </h5>
          </div>
          <div className='text-xs'>
            <div
              className='jodit_wysiwyg'
              dangerouslySetInnerHTML={{
                __html: activeBonus?.termsDescTran?.[switchLanguage(i18n)],
              }}
            />
            {/* {terms} */}
          </div>
        </div>
        <div className='footer'>
          <div className='flex justify-center gap-6'>
            {
              activeBonus?.redirectUrl
                ? (
                  <CommonButton
                    loading={loading}
                    title={t('common.checkItOut')}
                    onPress={() => {
                      router.push(activeBonus?.redirectUrl);
                    }}
                  />
                ) : !isLoggedIn ? (
                  <Button
                    className='bg-headerbg h-12 rounded-lg text-center w-full'
                    onClick={() => {
                      toggleShow();
                      dispatch(SettingActions.setLoginShow(true));
                    }}
                  >
                    <span className='text-sm font-normal text-white capitalize'>
                      {t('common.loginSignupToClaim')}
                    </span>
                  </Button>
                )
                  : activeBonus?.available ? (
                    <CommonButton
                      loading={loading}
                      title={t('common.claimNow')}
                      onPress={async () => {
                        if (activeBonus.minDepositAmount === 0) {
                          setLoading(true);
                          const result = await SubmitDeposit({
                            bonusId: activeBonus?._id.toString(),
                            amount: 0,
                          });
                          if (result.status === 201) {
                            toggleShow();
                            setSuccessFailInfo({ status: 'success', message: 'Claim Successfully' });
                            setShowSuccessFailModal(true);
                          } else {
                            toggleShow();
                            setSuccessFailInfo({ status: 'fail', message: result?.response.data?.message });
                            setShowSuccessFailModal(true);
                          }
                          return setLoading(false);
                        }
                        router.push(`/deposit?bonusId=${activeBonus?._id}`);
                      // toggleShow();
                      // toggleProfileModal();
                      }}
                    />
                  ) : null

            }

          </div>
        </div>
      </div>
    </div>
  );
}
