import {
  CloseOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form, Input,
  message,
} from 'antd';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import ReCAPTCHA from 'react-google-recaptcha';
import { Register } from '../../api/registration';
import { SendVerifyCode } from '../../api/sendVerifyCode';
import { SettingActions } from '../reducers/settingReducer';
import {
  RegisterBannerNoLogo,
  logoIcon, SignupBannerMobile,
} from '../../images';
import StrengthMeter from './StrengthMeter';
import { RECAPTCHA_SITEKEY } from '../../api/constants';

function RegisterModal() {
  const branchId = process.env.BRANCH_ID;
  const { t } = useTranslation('common');
  const router = useRouter();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [referrerCode, setReferrerCode] = useState('');
  const registerShow = useSelector((state) => state.setting.registerShow);
  const [isError, setError] = useState(null);
  const [passwordStatus, setPasswordStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [captchaKey, setCaptchaKey] = useState(null);
  const [time, setTime] = useState('');
  const referrer_link = router.query.referrer;
  const referrer_local = (typeof window !== 'undefined' && window.localStorage) ? (localStorage.getItem('referrer') ? (JSON.parse(localStorage.getItem('referrer'))) : '') : '';
  const referrer_cookies = Cookies.get('referrer') ? (JSON.parse(Cookies.get('referrer'))) : '';
  const [originAffiliate, setOriginAffiliate] = useState('');
  const isMobile = useSelector((state) => state.setting.isMobile);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (time === '00:00') {
      setShowModal(false);
    }
  }, [time]);

  // Function to validate "confirm password" field
  const validateConfirmPassword = (_, value) => {
    const { password } = form.getFieldsValue();
    if (value && value !== password) {
      return Promise.reject(t('register.twoPasswordsDoNotMatch'));
    }

    return Promise.resolve();
  };

  // Function to handle changes in the "password" field
  const handlePasswordChange = () => {
    form.validateFields(['confirmPassword']);

    setError(null);
    let caps; let small; let num; let
      specialSymbol;

    const password = form.getFieldValue('password');
    if (password.length < 4) {
      setError(
        t('register.passwordMinCharacters'),
      );
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/\W/g) || []).length;
      if (caps < 1) {
        setError(t('register.mustAddOneUppercaseLetter'));
      } else if (small < 1) {
        setError(t('register.mustAddOnelowercaseLetter'));
      } else if (num < 1) {
        setError(t('register.mustAddOneNumber'));
      } else if (specialSymbol < 1) {
        setError(t('register.mustAddOneSymbol'));
      }
    }
  };

  useEffect(() => {
    if (referrer_link) {
      form.setFieldsValue({ referrer: referrer_link });
      setOriginAffiliate(referrer_link);
    }
    if (referrer_cookies) {
      if (!isEmpty(referrer_cookies)) {
        if (moment(referrer_cookies.expiryDate) >= moment()) {
          if (referrer_cookies.referrerCode) {
            form.setFieldsValue({ referrer: referrer_cookies.referrerCode });
            setOriginAffiliate(referrer_cookies.referrerCode);
          }
        }
      }
    }
    if (referrer_local) {
      if (!isEmpty(referrer_local)) {
        if (moment(referrer_local.expiryDate) >= moment()) {
          if (referrer_local.referrerCode) {
            form.setFieldsValue({ referrer: referrer_local.referrerCode });
            setOriginAffiliate(referrer_local.referrerCode);
          }
        }
      }
    }
  }, [referrer_link, referrer_cookies, referrer_local]);

  const onFinish = async (values) => {
    if (passwordStatus === 'weak' || isError) {
      const errorMessage = passwordStatus === 'weak' ? t('register.pleaseChooseStrongerPassword') : isError;
      return message.error(errorMessage);
    }
    if (!captchaKey) {
      const errorMessage = 'Please check reCAPTCHA';
      return message.error(errorMessage);
    }

    // get origin affiliate
    if (originAffiliate) {
      values.originAffiliate = originAffiliate;
    }

    dispatch(SettingActions.setLoading(true));

    const result = await Register({
      captchaKey,
      ...values,
      branchId,
      noVerifications: true,
    });
    if (!result?.error) {
      // store token
      Cookies.set('accessToken', result?.data?.accessToken);
      Cookies.set('userId', result?.data?.userId);
      Cookies.set('username', result?.data?.username);
      Cookies.set('fullName', result?.data?.fullName);
      dispatch(SettingActions.setAlert([{ msg: t('register.registrationSuccess') }]));
      dispatch(SettingActions.setLoginShow(false));
      dispatch(SettingActions.setRegisterShow(false));
      dispatch(SettingActions.setLoading(false));

      Cookies.remove('referrer');
      localStorage.removeItem('referrer');

      form.resetFields();
      router.push('/');
    } else {
      // show error
      dispatch(SettingActions.setLoginShow(false));
      // dispatch(SettingActions.setRegisterShow(false));
      dispatch(SettingActions.setLoading(false));
      dispatch(
        SettingActions.setAlert([
          { msg: `${t('register.registrationFail')} : ${result?.error?.msg}` },
        ]),
      );
    }
    recaptchaRef.current.execute();
  };

  const sendCode = async (affiliate) => {
    const phoneNumber = form.getFieldValue('phoneNumber');

    const hasError = form.getFieldError('phoneNumber').length > 0;

    if (hasError) {
      dispatch(SettingActions.setAlert([{ msg: t('register.invalidPhoneNumberFormat') }]));
      return false;
    }
    dispatch(SettingActions.setLoading(true));
    setTimeout(async () => {
      const result = await SendVerifyCode({
        phoneNumber,
        branchId,
        type: '1', // registration
        originAffiliate: affiliate,
      });
      if (!result?.error) {
        dispatch(SettingActions.setAlert([{ msg: t('register.codeSent') }]));
      } else {
        dispatch(
          SettingActions.setAlert([{ msg: `${t('register.fail')} : ${result?.error?.msg}` }]),
        );
      }
      dispatch(SettingActions.setLoading(false));
    }, 1000);
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const handleChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
  };

  return (
    <div
      id='popup-modal'
      tabIndex='-1'
      className={
        registerShow
          ? 'md:p-10 overflow-scroll bg-purpleblack fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary gradient show md:px-60'
          : 'md:p-10 overflow-scroll bg-purpleblack fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary gradient md:px-60'
      }
      aria-modal='true'
      role='dialog'
    >
      <div
        className='overlay-closeable'
        onClick={() => {
          if (router.asPath === '/login' || router.asPath === '/register') {
            window.history.replaceState('', '', '/');
          } else {
            window.history.replaceState('', '', router.asPath);
          }
          dispatch(SettingActions.setRegisterShow(false));
        }}
      />
      <div className='relative rounded z-10 modal-dialog wide-xl'>
        <div className='absolute top-1 right-2 m-auto text-center'>
          <Button
            onClick={() => {
              if (router.asPath === '/login' || router.asPath === '/register') {
                window.history.replaceState('', '', '/');
              } else {
                window.history.replaceState('', '', router.asPath);
              }
              dispatch(SettingActions.setRegisterShow(false));
            }}
            className='close-button relative z-10'
          >
            <CloseOutlined
              fill='white'
              style={{ fontSize: '20px', color: 'white' }}
            />
          </Button>
        </div>
        <div className='md:flex'>
          <div className='md:w-2/5 flex flex-col justify-end items-center' style={{ lineHeight: 0 }}>
            <div className='leading-none absolute md:top-3 top-6 mt-10'>
              {isMobile ? (
                null
              ) : (
                <Image src={logoIcon} alt='' width={130} height={50} />
              )}
            </div>
            <div className='lg:mt-10'>
              {isMobile ? (
                <Image src={SignupBannerMobile} alt='Register Banner' />
              ) : (
                <Image src={RegisterBannerNoLogo} alt='Register Banner' />
              )}
            </div>
          </div>
          <div className={`md:w-3/5 fade-in-up ${!isMobile && 'border-l-4 border-dark'}`}>
            <div className='px-8 flex items-center w-full h-full'>
              <div className='w-full'>
                <div className='mt-4 mb-2 text-2xl font-bold'>
                  {t('register.signUpNow')}
                </div>
                <Form
                  form={form}
                  initialValues={{
                    referrer: referrerCode,
                    dialCode: '+55',
                  }}
                  onFinish={onFinish}
                >
                  <div>
                    <div className='grid grid-cols-2 gap-2 mb-4'>
                      <Form.Item
                        name='username'
                        label={`${t('login.username')}`}
                        rules={[
                          {
                            required: true,
                            message: t('register.pleaseInputUsername'),
                          },
                          {
                            pattern: /^[^\s]+$/,
                            message: t('register.usernameCannotContainSpaces'),
                          },
                          {
                            pattern: /^[a-zA-Z0-9]+$/,
                            message: t('register.usernameAlphanumeric'),
                          },
                        ]}
                      >
                        <Input
                          className='w-full rounded-sm h-10 mt-1 px-4'
                          placeholder={t('register.pleaseInputUsername')}
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          onPaste={handleKeyDown}
                        />
                      </Form.Item>
                      <Form.Item
                        name='fullName'
                        label={`${t('register.fullName')}`}
                        rules={[
                          {
                            required: true,
                            message: t('register.pleaseInputFullName'),
                          },
                        ]}
                      >
                        <Input
                          className='w-full rounded-sm h-10 mt-1 px-4'
                          placeholder={t('register.pleaseInputFullName')}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='email'
                      label={t('register.email')}
                      rules={[
                        {
                          required: true,
                          message: t('register.pleaseInputEmail'),
                        },
                      ]}
                      className='mb-4'
                    >
                      <Input
                        className='w-full rounded-sm h-10 mt-1 px-4'
                        placeholder={t('register.pleaseInputEmail')}
                      />
                    </Form.Item>

                    <Form.Item
                      name='phoneNumber'
                      label={t('register.mobileNo')}
                      rules={[
                        {
                          required: true,
                          message: t('register.pleaseInputPhoneNo'),
                        },
                        {
                          pattern: /^55\d{10,11}$/, // Update the regex pattern to allow 10 to 11 digits after "55"
                          message: t('register.invalidPhoneNumFormat'),
                        },
                      ]}
                      className='mb-4'
                    >
                      <Input
                        className='w-full rounded-sm h-10 mt-1 px-4'
                        placeholder={t('register.pleaseInputPhoneNo')}
                      />
                    </Form.Item>

                    <div className='grid grid-cols-10 gap-2 mb-4'>
                      <div className='col-span-7 mb:col-span-3'>
                        <Form.Item
                          name='verifyContactNoCode'
                          label={t('register.verifyContactNoCode')}
                          rules={[
                            {
                              required: true,
                              message: t('register.pleaseInputCode'),
                            },
                            {
                              pattern: /^\d*$/, // Regex pattern for any number of digits
                              message: t('register.onlyNumberAllow'),
                            },
                          ]}
                        >
                          <Input
                            className='w-full rounded-sm h-10 mt-1 px-4'
                            placeholder={t('register.pleaseInputCode')}
                          />
                        </Form.Item>
                      </div>
                      <div className='col-span-3 mb:col-span-2 mt-7'>
                        <Button onClick={() => sendCode(referrer_cookies)} className='h-10 button-primary w-full'>
                          <span className='text-xs text-white font-normal'>
                            {t('register.sendCode')}
                          </span>
                        </Button>
                      </div>
                    </div>

                    <div className='grid grid-cols-2 gap-2'>
                      <div>
                        <Form.Item
                          name='password'
                          label={`${t('register.password')}`}
                          rules={[
                            {
                              required: true,
                              message: t('register.pleaseInputPassword'),
                            },
                          ]}
                          className='mb-1'
                        >
                          <Input
                            type='password'
                            className='w-full rounded-sm h-10 mt-1 px-4'
                            onChange={handlePasswordChange}
                            placeholder={t('register.pleaseInputPassword')}
                            maxLength={30}
                          />
                        </Form.Item>
                        <div className='mb-2'>
                          {isError !== null && (
                            <p className='errors'>
                              {' '}
                              -
                              {' '}
                              {isError}
                            </p>
                          )}
                          <StrengthMeter password={form.getFieldValue('password')} actions={setPasswordStatus} />
                        </div>
                      </div>
                      <Form.Item
                        name='confirmPassword'
                        dependencies={['password']}
                        label={`${t('register.confirmPassword')}*`}
                        rules={[
                          {
                            required: true,
                            message: t('register.pleaseInputPassword'),
                          },
                          {
                            validator: validateConfirmPassword,
                          },
                        ]}
                      >
                        <Input
                          type='password'
                          className='w-full rounded-sm h-10 mt-1 px-4'
                          placeholder='Please enter confirm password'
                          maxLength={30}
                        />
                      </Form.Item>
                    </div>

                    <div className='text-end mb-4'>
                      <ReCAPTCHA
                        // size='invisible'
                        sitekey={RECAPTCHA_SITEKEY}
                        ref={recaptchaRef}
                        onChange={(e) => {
                          setCaptchaKey(e);
                        }}
                      />
                    </div>

                    <Form.Item
                      name='referrer'
                      label={t('register.referrerCode')}
                      style={{ margin: 0 }}
                    >
                      <Input
                        className='w-full rounded-sm h-10 mt-1 px-4'
                        placeholder='Eg: HveDqsb6'
                      />
                    </Form.Item>
                  </div>

                  <Form.Item className='text-center'>
                    <Button
                      htmlType='submit'
                      className='h-12 button-primary w-full mt-5 mb-4'
                    >
                      <span className='text-base font-semibold text-white'>
                        {/* {t('common.login')} */}
                        {t('register.go')}
                      </span>
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterModal;
