/* eslint-disable */
import { useRouter } from 'next/router';
import React, { useState } from 'react';
// import { parseCookies } from "../lib/parseCookies";
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { UserActions } from '../store/slices/userSlice';

function ChangeLanguageButton(props) {
  const { t, i18n } = useTranslation('common');
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const router = useRouter();
  const [checked, setChecked] = useState(currentLanguage !== 'en-BR');

  const changeLocale = () => {
    const newLanguage = currentLanguage === 'en-BR' ? 'pt-BR' : 'en-BR';
    i18n.changeLanguage(newLanguage).then(() => {
      dispatch(UserActions.setLocale(newLanguage));
      router.push(router.pathname, router.asPath, { locale: newLanguage });
    });
  };

  return (
    <div>
      <label htmlFor='checkbox'>
        <input
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
            changeLocale();
        }}
          type='checkbox'
          name=''
          id='checkbox'
        />
        <div className='toggle'>
          <div className='star1' />
          <div className='star2' />
        </div>
      </label>
    </div>
  );
  // const returnLanguageButton = () => {
  //   if (currentLanguage === 'en') {
  //     return (
  //       <div
  //         className='flex flex-row items-center align-center'
  //         onClick={() => changeLocale('pt')}
  //       >
  //         <Image
  //           className='w-7 h-7'
  //           src={englishLanguageIcon}
  //           alt='English'
  //           width={isMobile ? 24 : 30}
  //           height={isMobile ? 24 : 30}
  //         />
  //         <p className='px-2 text-sm font-sans'>
  //           EN
  //         </p>
  //       </div>
  //     );
  //   }
  //   return (
  //     <div
  //       className='flex flex-row items-center align-center'
  //       onClick={() => changeLocale('en')}
  //     >
  //       <p className='px-2 text-xs md:text-sm font-sans'>
  //         PT
  //       </p>
  //       <Image
  //         className='w-7 h-7'
  //         src={portugueseLanguageIcon}
  //         alt='Portuguese'
  //         width={isMobile ? 24 : 30}
  //         height={isMobile ? 24 : 30}
  //       />
  //     </div>

  //   );
  // };

  // return (
  //   <div style={{
  //             display: 'flex',
  //             flexDirection: 'row',
  //             cursor: 'pointer',
  //             color: 'white',
  //             padding: '0.2rem',
  //             paddingTop: '0.1rem',
  //             paddingBottom: '0.1rem',
  //             border: '1px solid',
  //             borderRadius: '9999px',
  //             width: 'fit-content',
  //             textAlign: 'center',
  //           }}
  //   >
  //     {returnLanguageButton()}
  //   </div>
  // );
}

export default ChangeLanguageButton;
