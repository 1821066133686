const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('../constants');

const SubmitDeposit = (data) => axios
  .request({
    method: 'POST',
    url: `${ENDPOINT}/deposits`,
    headers: {
      Authorization: `Bearer ${
        Cookies.get('accessToken') || data?.accessToken
      }`,
    },
    data,
  })
  .then((response) => response)
  .catch((error) => (error));

module.exports = {
  SubmitDeposit,
};
