import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Form, Input,
} from 'antd';
// import axios from 'axios';
import { useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Auth } from '../../api/auth';
import { SettingActions } from '../reducers/settingReducer';
import ForgotPasswordModal from './ForgotPasswordModal';
import { LayoutContext } from '../contexts/LayoutContextContainer';
import { getBrand } from '../../api/getBrand';
import { getGameCred } from '../../api/getGameCred';
import {
  logoIcon,
  SignupBannerNoLogo, SignupBannerMobile,
} from '../../images';

function LoginModal() {
  const branchId = process.env.BRANCH_ID;
  const [form] = Form.useForm();
  const router = useRouter();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const loginShow = useSelector((state) => state.setting.loginShow);
  const [show, setShow] = useState(false);
  const isMobile = useSelector((state) => state.setting.isMobile);
  const { toPath } = useContext(LayoutContext);

  const toggleShowModal = () => {
    setShow(!show);
  };

  useEffect(() => {
    dispatch(SettingActions.setLoading(false));
  }, []);

  const accessToSport = async (hasPath) => {
    const gameInfo = await getGameCred({ brandId: process.env.SPORT_ID });

    if (gameInfo?.error) {
      if (!gameInfo?.error.includes('jwt')) {
        dispatch(
          SettingActions.setAlert([
            {
              msg: gameInfo?.error,
            },
          ]),
        );
      }
      return false;
    }

    const brandInfo = await getBrand(process.env.SPORT_ID);
    const gameProfileId = gameInfo?.data?.data?.[0]._id;
    const brandId = brandInfo?.data?._id;
    const gameId = brandInfo?.data?.games?._id;
    router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}${hasPath ? `&toPath=${hasPath}` : ''}`);
  };

  const onFinish = async (values) => {
    dispatch(SettingActions.setLoading(true));
    const result = await Auth({
      ...values, branchId, noVerifications: true,
    });
    if (result) {
      Cookies.set('accessToken', result?.data?.accessToken);
      Cookies.set('userId', result?.data?.userId);
      Cookies.set('username', result?.data?.username);
      Cookies.set('fullName', result?.data?.fullName);
      //   router.push('/');
      dispatch(SettingActions.setLoading(false));
      dispatch(SettingActions.setLoginShow(false));
      // dispatch(SettingActions.setIsLogin(true));

      // if (router.pathname === '/game_website' && router?.query?.gameAccess) {
      //   accessToSport(toPath);
      // }
      
        // dispatch(SettingActions.setLoading(true));
        // window.location.reload();
      if (router.pathname === '/game_website') {
        accessToSport(toPath);
      } else {
        dispatch(SettingActions.setLoading(true));
        router.push('/');
        dispatch(SettingActions.setLoading(false));
      }
    } else {
      dispatch(SettingActions.setLoading(false));
      // dispatch(SettingActions.setLoginShow(false));
      dispatch(SettingActions.setAlert([{ msg: 'Login error' }]));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const handleChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
  };

  return (
    <>
      <div
        id='popup-modal'
        tabIndex='-1'
        className={`md:p-10 overflow-scroll bg-purpleblack fixed top-0 right-0 left-0 z-[50] h-modal h-full modal-primary gradient md:px-60 ${loginShow && 'show'}`}
        aria-modal='true'
        role='dialog'
      >
        <div
          className='overlay-closeable'
          onClick={() => {
            if (router.asPath === '/login' || router.asPath === '/register') {
              window.history.replaceState('', '', '/');
            } else {
              window.history.replaceState('', '', router.asPath);
            }
            dispatch(SettingActions.setLoginShow(false));
          }}
        />
        <div className='relative rounded z-10 modal-dialog wide-xl'>
          <div className='absolute top-1 right-2 m-auto text-center'>
            <Button
              onClick={() => {
                if (router.asPath === '/login' || router.asPath === '/register') {
                  window.history.replaceState('', '', '/');
                } else {
                  window.history.replaceState('', '', router.asPath);
                }
                dispatch(SettingActions.setLoginShow(false));
              }}
              className='close-button relative z-10'
            >
              <CloseOutlined
                fill='white'
                style={{ fontSize: '20px', color: 'white' }}
              />
            </Button>
          </div>
          <div className='md:flex md:min-h-min'>
            <div className='md:w-2/5 flex flex-col justify-end items-center' style={{ lineHeight: 0 }}>
              <div className='leading-none absolute md:top-3 top-6 mt-5'>
                <Image src={logoIcon} alt='' width={130} height={50} />
              </div>
              <div className='md:top-20'>
                {isMobile ? (
                  <Image src={SignupBannerMobile} alt='Register Banner' />
                ) : (
                  <Image src={SignupBannerNoLogo} alt='Register Banner' />
                )}
              </div>
            </div>
            <div className={`md:w-3/5 fade-in-up ${!isMobile && 'border-l-4 border-dark'}`}>
              <div className='px-8 pb-6 md:pb-0 flex items-center w-full h-full'>
                <div className='w-full'>
                  <div className='mt-2 md:mt-0 mb-3 text-2xl font-bold'>
                    {t('login.welcomeBack')}
                  </div>
                  <Form
                    id='LoginModal'
                    name='form'
                    form={form}
                    onFinish={onFinish}
                  >
                    <div className='mb-4'>
                      <div>
                        <Form.Item
                          label={t('login.username')}
                          name='username'
                          rules={[
                            {
                              required: true,
                              message: t('login.pleaseInputUsername'),
                            },
                          ]}
                          className='mb-3'
                        >
                          <Input
                            className='w-full rounded-sm h-10 mt-1 px-4'
                            placeholder={t('login.pleaseInputUsername')}
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          label={<>{t('login.password')}</>}
                          name='password'
                          rules={[
                            {
                              required: true,
                              message: t('login.pleaseInputPassword'),
                            },
                          ]}
                          className='mb-3'
                        >
                          <Input
                            type='password'
                            className='w-full rounded-sm h-10 mt-1 px-4'
                            placeholder={t('login.pleaseInputPassword')}
                            maxLength={30}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <Form.Item className='text-center'>
                      <Button
                        htmlType='submit'
                        className='h-12 button-primary w-full mb-5'
                      >
                        <span className='text-base font-semibold text-white'>
                          {/* {t('common.login')} */}
                          {t('login.go')}
                        </span>
                      </Button>
                    </Form.Item>
                  </Form>

                  <div className='flex items-center justify-between'>
                    <div
                      className='flex'
                    >
                      <p className='font-normal'>
                        {t('register.noAccount')}
                        {' '}
                        <span
                          className='font-normal underline cursor-pointer hover:opacity-70'
                          style={{ color: '#0BBF7B' }}
                          onClick={() => {
                            dispatch(SettingActions.setLoginShow(false));
                            dispatch(SettingActions.setRegisterShow(true));
                          }}
                        >
                          {t('register.createOne')}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p
                        className='font-normal underline cursor-pointer hover:opacity-70'
                        style={{ color: '#b2b3b3' }}
                        onClick={() => {
                          dispatch(SettingActions.setForgotPasswordShow(true));
                          dispatch(SettingActions.setLoginShow(false));
                        }}
                      >
                        {t('login.forgotPasswordText')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ForgotPasswordModal show={show} toggleModal={toggleShowModal} />
    </>
  );
}

export default LoginModal;
