import {
  fbIcon,
  igIcon,
  livechatIcon,
  tgIcon,
  waIcon,
  wechatIcon,
  youtubeIcon,
  TwitterLogo,
  Tiktok,
} from '../../images';

export const socialMediaList = [
  {
    type: 'fb',
    title: 'facebook',
    icon: fbIcon,
    link: 'https://www.facebook.com/profile.php?id=61559483617028&mibextid=LQQJ4d',
  },
  {
    type: 'youtube',
    title: 'youtube',
    icon: youtubeIcon,
    link: 'https://www.youtube.com/@PPX7BRAZIL',
  },
  {
    type: 'ig',
    title: 'instagram',
    icon: igIcon,
    link: 'https://www.instagram.com/ppx7.brazil/',
  },
  {
    type: 'twitter',
    title: 'twitter',
    icon: TwitterLogo,
    link: 'https://twitter.com/i/flow/login?redirect_after_login=%2Fppx7brazil',
  },
  {
    type: 'Tikok',
    title: 'Tikok',
    icon: Tiktok,
    link: 'https://www.tiktok.com/@ppx7.brazil',
  },
  // {
  //   type: 'printerest',
  //   title: 'printerest',
  //   icon: printerestIcon,
  //   value: '@jdk988official',
  //   action: () => { },
  // },
];
